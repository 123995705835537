import React, { useState, useEffect } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import fondoRecompesas from "assets/images/fondoRecompesas.svg";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ComponentWithBackground from "components/App/ComponentWithBackground";
import { useNavigate } from "react-router-dom";
import iconLink from "assets/images/IconLink.svg";
import IconFacebook from "assets/images/IconFacebook.svg";
import iconWhatsApp from "assets/images/IconWhatsApp.svg";
import iconTwitter from "assets/images/IconTwitter.svg";
import { BASE_URL, API_URL, WEBSITE } from "constants";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import zIndex from "@mui/material/styles/zIndex";

const Insignia = () => {
  const [EvaluationName, setEvaluationName] = useState("");
  const [titlePrincipal, setTitlePrincipal] = useState("");
  const [ImageCover, setImageCover] = useState("");
  const [Description, setDescription] = useState("");

  useEffect(() => {
    const wineId = localStorage.getItem("wine_id");
    const authToken = localStorage.getItem("authToken");
    if (wineId && authToken) {
      fetch(`${API_URL}/view-tastingend-phy`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          wine_id: wineId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setEvaluationName(data.evaluation_name || "");
          data.tasting_end_physical.image_cover = `${BASE_URL}/${data.tasting_end_physical.image_cover}`;
          setImageCover(data.tasting_end_physical?.image_cover || "");
          setTitlePrincipal(data.tasting_end_physical?.title || ""); // Modificación aquí
          setDescription(data.tasting_end_physical?.description || "");
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, []);

  const [open, setOpen] = React.useState(false);
  const [openT, setOpenT] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpenT(false);
  };

  const sharelink = (event) => {

    event.preventDefault();
    const anchor = event.target.closest("a"); 
    navigator.clipboard.writeText(anchor.getAttribute('href')).then(() => {
      /* clipboard successfully set */
      setOpenT(true);
      setTimeout(() => {
        setOpenT(false);
      }, 1000);
    }, () => {
      /* clipboard write failed */
      setOpenT(false);
    });

  };

  const handleHover = (event) => {
    event.target.style.backgroundColor = "#602131"; // Cambia el color de fondo a rojo cuando hay hover
    event.target.style.color = "#ffffff"; // Cambia el color del texto a blanco cuando hay hover
  };

  const handleLeave = (event) => {
    event.target.style.backgroundColor = "transparent"; // Restaura el color de fondo original cuando el hover termina
    event.target.style.color = "#602131"; // Restaura el color del texto original cuando el hover termina
  };

  /*const FacebookShareButton = () => {
        return (
            <div className="fb-share-button" data-href={window.location.href} data-layout="button" data-size="large">
                <a target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}&amp;src=sdkpreparse`} className="fb-xfbml-parse-ignore"><img src={IconFacebook} alt="Facebook Share" /></a>
            </div>
        );
    };*/

  const styles = {
    container: {
      position: "relative",
      width: "100%",
      //height: '468px',
      // backgroundImage: `url(${fondoRecompesas})`,
      backgroundSize: "100% auto",
      backgroundPosition: "center top",
      textAlign: "center",
    },
    contentContainer: {
      width: "100%",
      textAlign: "center",
      fontFamily: "Athelas",
      color: "#602131",
      marginTop:'3vh',
      marginBottom: "25px",
    },
    carousel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      width: "40%",
      marginLeft: "30%",
   
    },
    mainImage: {
      width: "100%",
      borderTopLeftRadius: "200px",
      borderTopRightRadius: "200px",
      border: "1px rgba(193, 161, 167, 0.25) solid",
    },
    roundedBox: {
      width: "55%",
      height: "110%",
      position: "absolute",
      top: "5px", // Aumentado para centrar el cuadro
      left: "50%", // Para centrar horizontalmente
      transform: "translateX(-50%)", // Para centrar horizontalmente
      borderTopLeftRadius: "200px",
      borderTopRightRadius: "200px",
      border: "1px rgba(193, 161, 167, 0.25) solid",
      zIndex: 1,
    },
    titlePrincipal: {
      color: "#602131",
      fontSize: "32px",
      fontFamily: "Athelas, serif",
      fontStyle: "italic",
      fontWeight: 600,
      lineHeight: "38.40px",
      wordWrap: "break-word",
      paddingTop: "5%", // Padding superior añadido
    },
    button: {
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      fontSize: "32px",
      color: "#ffffffff",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      //  zIndex: 3,
    },

    btn: {
      padding: "14px 30px",
      borderRadius: 50,
      border: "1px #602131 solid",
      color: "#602131",
      fontFamily: "Athelas",
      fontSize: "18px",
      fontWeight: "bold",
      textAlign: "center",
      cursor: "pointer",
      display: "block",
      width: "80%",
      margin: "0 auto",
      marginTop: "40px",
      marginBottom: "40px",
      backgroundColor: "transparent",
    },

    dotsContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "20px",
      zIndex: 4,
    },
    dot: {
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: "#C1A1A7", // Color de puntos no activos
      margin: "0 5px",
      cursor: "pointer",
      transition: "all 0.3s ease",
    },
    socialButtons: {
      display: "flex",
      flexdirection: "row",
      justifycontent: "center",
      alignitems: "center",
      padding: "0px",
      width: "232px",
    },
  };

  return (
    <div
      style={{
        height: "100%",
        weight: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={styles.container}>
        <div style={styles.carousel}>
          <img src={ImageCover} alt="Felicidades" style={styles.mainImage} />
        </div>
        <div style={styles.titlePrincipal}>{titlePrincipal}</div>
        <div
          style={{
            width: "100%",
            color: "#602131",
            fontSize: "18px",
            fontFamily: "Avenir",
            overflowWrap: "break-word",
            margin: "20px auto",
          }}
        >
          Lograste ganarte una recompensa a tu domicilio al finalizar<br/> la evaluacion&nbsp;
          <strong>{EvaluationName}</strong>
        </div>
        <a href="/datos-envio">
          <button
            style={styles.btn}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            onClick={handleOpen}
          >
            Llenar datos de envío
          </button>
        </a>
      </div>
      <div style={styles.contentContainer}>Comparte tu logro:</div>
      <div
        style={{
          ...styles.socialButtons,
          justifyContent: "space-between",
        }}
      >
        <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
                PopperProps={{ disablePortal: true }}
                onClose={handleTooltipClose}
                onBlur={handleTooltipClose}
                open={openT}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Link Copiado!"
              >
        <a href={`${WEBSITE}`} onClick={sharelink}>
          <img src={iconLink} />
        </a>
        </Tooltip>
        </ClickAwayListener>
        <FacebookShareButton
          url={window.location.href}
          quote="¡Mira lo que he conseguido!"
          className="fb-xfbml-parse-ignore"
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            window.location.href
          )}&amp;src=sdkpreparse`}
        >
          <a rel="noopener noreferrer">
            <img src={IconFacebook} alt="Facebook Share" />
          </a>
        </FacebookShareButton>
        <TwitterShareButton
          url={window.location.href}
          title="¡Mira lo que he conseguido!"
          hashtag="#reward #achievement #entrecopas"
          className="twitter-share-button"
        >
          <img src={iconTwitter} alt="Twitter Share" />
        </TwitterShareButton>
        <WhatsappShareButton
          url={window.location.href}
          title="¡Mira lo que he conseguido!"
        >
          <img src={iconWhatsApp} alt="WhatsApp Share" />
        </WhatsappShareButton>
      </div>
    </div>
  );
};

export default Insignia;
