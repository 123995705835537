import React, { useEffect, useState } from 'react';
import { API_URL } from 'constants';
import { BASE_URL } from 'constants';
import ImageCover from "assets/images/Group2659.jpg";

const useTastingQuestions = (questionPath, questionKey) => {

    const [tastingData, setTastingData] = useState(null);
    const [imageCover, setImageCover] = useState(null);
    const [messageEror, setmessageEror] = useState(null);

    useEffect(() => {

        const wineCodeFromCache = localStorage.getItem('codigo_wine');
        const authToken = localStorage.getItem('authToken');

        if (wineCodeFromCache) {
            fetch(`${API_URL}/${questionPath}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({
                    code: wineCodeFromCache
                })
            })
                .then(response => response.json())
                .then(data => {

                    if(data.status == "error"){

                        //window.location.href=`evaluation_error/${questionPath}`;
                        setmessageEror(data.message);
                        setImageCover(ImageCover);

                    }else{
                        setTastingData(data[questionKey]);
                        data.encabezado.image_cover = `${BASE_URL}/${data.encabezado.image_cover}`;
                        //setImageCover(data.tasting_end?.image_cover || "");
                        setImageCover(data.encabezado.image_cover);
                    }
                })
                .catch(error => console.error('Error:', error));
        }

    }, []);

    return [tastingData, imageCover, messageEror];

};

export default useTastingQuestions;