import React, { useState, useEffect } from "react";
import SingleNavbar from "components/App/SingleNavbar";
import ChangePassword from "components/App/ChangePassword";
import Footer from "components/App/Footer";

import headerBackGround from "assets/images/headerBackGround.png";
import headerBackGroundLarge from "assets/images/headerBackGroundLarge.png";
// Asegúrate de tener esta imagen

function LayoutLoginRegister() {

  return (
    <div
    style={{
      width: "100%",
      height:'100vh',
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#FFFFFF",
    }}
  >
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SingleNavbar />
    </div>

    <div
      style={{
        width: "100%",
        height:'100%',
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ChangePassword />
    </div>
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Footer />
    </div>
  </div>
  );
}

export default LayoutLoginRegister;
