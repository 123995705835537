import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from 'styles/App.module.css';
import iconHelp from "assets/images/iconHelp.svg";
import iconRewards from "assets/images/iconRewards.svg";
import iconLogin from "assets/images/iconLogin.svg";
import iconHelpBold from "assets/images/iconHelpBold.svg";
import iconRewardsBold from "assets/images/iconRewardsBold.svg";
import iconLoginBold from "assets/images/iconLoginBold.svg";

const MenuHeader = () => {
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem('authToken');
  const [boldText, setBoldText] = useState(isLoggedIn ? "Cerrar sesión" : "Iniciar sesión");

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/login');
  };

  const handleMenuItemClick = (buttonText) => {
    setBoldText(buttonText);
  };

  return (
    <div className={styles.containerMenuHeader}>
      <div className={styles.menuItems}>
        
        <button 
          className={styles.menuItem} 
          onClick={() => handleMenuItemClick("Ayuda")} 
          style={{ fontWeight: boldText === "Ayuda" ? 900 : 400 }}
        >
          <Link to="/centro-ayuda">
            <img 
              className="w-[26px] h-[26px]" 
              src={iconHelpBold } 
              alt="Ayuda Icon" 
            />
            <div className={styles.menuItemText}>Ayuda</div>
          </Link>
        </button>

        <button 
          className={styles.menuItem} 
          onClick={() => handleMenuItemClick("Recompensas")} 
          style={{ fontWeight: boldText === "Recompensas" ? 900 : 400 }}
        >
           <Link to={isLoggedIn ? "/recompensas" : "/sin-cuenta"}>
            <img 
              className="w-[26px] h-[26px]" 
              src={ iconRewardsBold } 
              alt="Recompensas Icon" 
            />
            <div className={styles.menuItemText}>Recompensas</div>
          </Link>
        </button>

        <button 
          className={styles.menuItem} 
          onClick={isLoggedIn ? handleLogout : () => handleMenuItemClick("Iniciar sesión")} 
          style={{ fontWeight: isLoggedIn ? 900 : boldText === "Iniciar sesión" ? 900 : 400 }}
        >
          <Link to="/login">
            <img 
              className="w-[26px] h-[26px]" 
              src={isLoggedIn || boldText === "Iniciar sesión" ? iconLoginBold : iconLogin} 
              alt={isLoggedIn ? "Cerrar sesión Icon" : "Iniciar sesión Icon"} 
            />
            <div className={styles.menuItemText} style={{ textDecoration: 'none' }}>
              {isLoggedIn ? "Cerrar sesión" : "Iniciar sesión"}
            </div>
          </Link>
        </button>

      </div>
    </div>
  );
};

export default MenuHeader;
