import React from 'react';
import { Link, useNavigate, Redirect } from 'react-router-dom';
import preguntasHeader from "assets/images/vino.svg";
import Modal from 'components/App/Modal'; // Importamos el componente de Modal desde su archivo separado
import Footer from "components/App/Footer";

const isLoggedIn = !!localStorage.getItem('authToken');

const styles = {
    container: {
        backgroundImage: `url(${preguntasHeader})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        textAlign: 'center', // Centrar el contenido horizontalmente
        color: '#602131',
        width:'100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-60px',
        marginBottom: '-100px',
        position: 'relative',
        
    },
    content: {
        width: '100%', // Limitar el ancho del contenido para que no se extienda demasiado
        textAlign: 'right', // Alinear el texto y el botón a la derecha
        marginBottom:'70px',
    },
    text: {
        marginLeft: "60px",
        fontFamily: 'Athelas',
        fontStyle: "Italic",
        fontWeight: "bold",
        fontSize: '40px',
        textAlign: "center"
    },
    btn: {
        backgroundColor: 'transparent',
        padding: '14px 30px',
        borderRadius: 50,
        border: '1px #602131 solid',
        color: '#602131',
        cursor: 'pointer',
        width: '65%',
        marginTop: '20px',
        marginBottom: '40px',
        fontFamily: 'Athelas',
        fontWeight: "bold",
        fontSize: '18px',
        marginRight:'20px'
    },
    btnHover: {
        backgroundColor: "#502021",
        color: "white",
      },
};

const ComponentWithBackground = () => {
    const [open, setOpen] = React.useState(false);
    const [description, setDescription] = React.useState("");
    const [btnHover, setBtnHover] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMouseEnter = () => {
        setBtnHover(true);
      };
    
      const handleMouseLeave = () => {
        setBtnHover(false);
      };

    return (
        <div style={styles.container}>
            <div style={styles.content}>
                <div style={styles.text}>Comienza aquí la<br/> mejor experiencia <br/>en cata de vinos</div>
                <button style={
              btnHover
                ? { ...styles.btn, ...styles.btnHover, color: "white" }
                : styles.btn
            } onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleOpen}>Comenzar</button>
            </div>
            <Modal open={open} handleClose={handleClose} description={description} />
        </div>
    );
};

export default ComponentWithBackground;
