import React, { useEffect, useState } from 'react';
import { BASE_URL, API_URL } from 'constants';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const WineEvaluationComponent = () => {
    const [wineId, setWineId] = useState(null);
    const [tastingData, setTastingData] = useState(null);  
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
    
        setOpenSnackbar(false);
    };

    useEffect(() => {
        // Obtener el wine_code del cache (aquí se asume que está almacenado en algún lugar llamado cache)
        const wineCodeFromCache = localStorage.getItem('codigo_wine');

        // Verificar si se encontró el wine_code en el cache
        if (wineCodeFromCache) {
            const authToken = localStorage.getItem('authToken');
           
            // Realizar solicitud a la API utilizando el wine_code y el token
            fetch(`${API_URL}/view-tasting`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({
                    code: wineCodeFromCache
                })
            })
                .then(response => response.json())
                .then(data => {

                    if(data.status == "error"){
                        
                        setSnackbarMessage(data.message);
                        setSnackbarSeverity("error");
                        setOpenSnackbar(true);
                        setTimeout(() => {
                            window.location.href = '/perfil-code';
                          }, 3000);
                    
                    }else{

                        // Guardar el wine_id en el localStorage
                        localStorage.setItem('wine_id', data.wine_id);
                        setWineId(data.wine_id);                   
                        data.tasting.rewards.image = `${BASE_URL}/${data.tasting.rewards.image}`;
                        setTastingData(data.tasting);

                    }
                })
                .catch(error => console.error('Error:', error));
        }
    }, []);

    const redirectToVisualEvaluation = () => {
        window.location.href = '/video-visual'; // Redirigir a evaluacion_visual
    };

    const styles = {
        container: {
            backgroundColor: '#F7F3EF',
            color: '#602131',
            fontFamily: 'Arial, sans-serif',
            padding: '20px',
            textAlign: 'left',
            position: 'relative',
        },


        imageContainer: {
            width: "100%",
            overflow: "hidden", // Para asegurar que la imagen no sobresalga del contenedor
        },
        image: {
            height: '200px', // Altura fija de 300px
            width: '100%', // Ancho completo
            position: 'fixed', // Posición fija para que esté pegado
            top: 0, // Pegado en la parte superior
            left: 0, // Pegado en la parte izquierda
            marginBottom: "100px",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        },
        title: {
            fontWeight: 'bold',
            fontSize: '21px',
            marginBottom: '20px',
            fontFamily:'Athelas',
        },
        content: {
            fontSize: '14px',
            lineHeight: '1.6',
            marginBottom: '20px',
            fontFamily:'Avenir'
        },
        listItem: {
            marginBottom: '10px',
            paddingLeft: '20px', // Espacio adicional para los puntos
            listStyleType: 'none', // Eliminar estilos predeterminados de la lista
        },
        bullet: {
            fontWeight: 'bold', // Puntos en negrita
            fontSize: '20px', // Tamaño de los puntos un poco más grande
            marginRight: '5px', // Margen derecho para separar los puntos del texto
        },
        btn: {
            padding: '14px 30px',
            borderRadius: 50,
            border: '1px #602131 solid',
            color: '#602131',
            fontFamily: 'Athelas',
            fontSize:'18px',
            fontWeight: 'bold',
            textAlign: 'center',
            cursor: 'pointer',
            display: 'block',
            width: '80%',
            margin: '0 auto',
            marginTop: '20px',
            marginBottom: '20px',
            backgroundColor:'transparent',
        },
        whiteBtn: {
            padding: '10px 10px',
            color: '#602131',
            cursor: 'pointer',
            borderRadius: 50,
            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)', // Agregar sombra
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '95%',
            margin: '0 auto',
            marginTop: '20px',
            backgroundColor: '#FFFFFF',
            border: 'none',
            lineHeight: 1,
            fontSize: '14px', // Tamaño de letra más pequeño
        },
        logoSmall: {
            width: '50px',
            height: 'auto',
            marginRight: '10px',
            borderRadius:'50%'
        },
        additionalInfo: {
            flex: 1,
            textAlign: 'left',
            fontFamily: 'Inter',
            fontSize: '10px', 
        },
        additionalInfoTitle: {
            fontSize: '12px', // Tamaño de letra para el título más pequeño
            fontWeight: 'bold',
            fontFamily: 'Athelas',
            marginBottom:'5px'
        },
        additionalInfoText: {
            fontSize: '11px', // Tamaño de letra para el texto más pequeño
        },
    };
    const handleHover = (event) => {
        event.target.style.backgroundColor = '#602131'; // Cambia el color de fondo a rojo cuando hay hover
        event.target.style.color = '#ffffff'; // Cambia el color del texto a blanco cuando hay hover
    };

    const handleLeave = (event) => {
        event.target.style.backgroundColor = '#fff'; // Restaura el color de fondo original cuando el hover termina
        event.target.style.color = '#000'; // Restaura el color del texto original cuando el hover termina
    };

    return (
        <div style={styles.container}>
            <div style={styles.containerContent}>
                <div style={styles.title}>{tastingData && tastingData.title}</div>
                <div style={styles.content}>
                    {tastingData && tastingData.description}
                </div>
                <div style={styles.content}>
                    <div style={styles.title}>{tastingData && tastingData.title_question}</div>
                    <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                        {tastingData && tastingData.list_req_tasting.map((item, index) => (
                            <li key={index} style={styles.listItem}><span style={styles.bullet}>•</span>{item.requirement}</li>
                        ))}
                    </ul>
                </div>
                <button style={styles.whiteBtn}>
                    <img src={tastingData && tastingData.rewards.image} alt={tastingData && tastingData.rewards.title} style={styles.logoSmall} />
                    <div style={styles.additionalInfo}>
                        <div style={styles.additionalInfoTitle}>{tastingData && tastingData.rewards.title}</div>
                        <div style={styles.additionalInfoText}>{tastingData && tastingData.rewards.description}</div>
                    </div>
                </button>
                <button style={styles.btn} onMouseEnter={handleHover}
                    onMouseLeave={handleLeave} onClick={redirectToVisualEvaluation}>Comenzar</button>
            </div>
            <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ height: "100%", whiteSpace: "pre-wrap" }}
        >
            {snackbarMessage}
        </Alert>
      </Snackbar>
        </div>
    );
};

export default WineEvaluationComponent;
