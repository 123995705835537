import React, { useState, useEffect } from "react";
import SingleNavbar from "components/App/SingleNavbar";
import ChangePassword from "components/App/Revisa";
import Footer from "components/App/Footer";

import headerBackGround from "assets/images/headerBackGround.png";
import headerBackGroundLarge from "assets/images/headerBackGroundLarge.png";
// Asegúrate de tener esta imagen

function LayoutLoginRegister() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles = {
    body: {
      height: "100%",
      margin: 0,
      padding: 0,
      background:
        "#F7F3EF" /* Sets the background color of the app to a light beige */,
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      margin: "65px",

      gap: "16px",
    },
    footer: {
      marginTop: "-350px",
    },
  };
  return (
    <div
    style={{
      width: "100%",
      height:'100vh',
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#FFFFFF",
    }}
  >
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SingleNavbar />
    </div>

    <div
      style={{
        width: "100%",
        height:'100%',
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ChangePassword />
    </div>
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Footer />
    </div>
  </div>
  );
}

export default LayoutLoginRegister;
