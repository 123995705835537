import React, { useState, useEffect } from "react";
import NavBarButton from "components/App/NavBarButton";
import MenuHeader from "components/App/MenuHeader";
import HeaderText from "components/App/HeaderText";
import Participation from "components/App/Participation";
import Carrousel from "components/App/Carrousel";
import Footer from "components/App/Footer";
import { Box, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import headerBackGround from "assets/images/headerBackGround.png";
import headerBackGroundLarge from "assets/images/headerBackGroundLarge.png";
import ComponentWithBackground from "./ComponentWithBackground";
import useWindowSize from "hooks/useWindowSize";
import shareIcon from "assets/images/share-icon.png";
// Asegúrate de tener esta imagen

function App() {
  const windowWidth = useWindowSize();

  const [showPopup, setShowPopup] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    // Check if the device is running iOS
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent) && !window.MSStream);
  }, []);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      // Prevent the default prompt from appearing immediately
      e.preventDefault();
      // Save the event for later use
      setDeferredPrompt(e);
      // Show the popup
      setShowPopup(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const showAddToHomeScreenPrompt = () => {
    if (deferredPrompt) {
      // Show the prompt
      deferredPrompt.prompt();
      // Check the user's response
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        setDeferredPrompt(null);
        setShowPopup(false);
      });
    }
  };

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
      }}
    >
      {isIOS && (
        <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          backgroundColor: "transparent",
          width: "100%",
          zIndex: "10000",
        }}
      >
        <div
          style={{
            margin: "10px 10px",
            padding: "10px 10px",
            backgroundColor: "#FFFFFF",
            border: "none",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <IconButton
              size="small"
              aria-label="close"
              sx={{ cursor: "pointer", color: "black" }}
              onClick={() => setIsIOS(false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
          <div
            style={{
              width: "90%",
              textAlign: "center",
              fontFamily: "Avenir",
              fontSize: "14px",
              color: "#000000",
            }}
          >
            ¡Guarda la página en tu celular para acceder rápidamente a futuras catas de vino!<br/><br/>
            Da clic en tu celular en el icono de COMPARTIR y elige la opción de AGREGAR AL INICIO.
          </div>
          <div
            style={{
              width: "90%",
              textAlign: "center",
              fontFamily: "Avenir",
              fontSize: "14px",
              color: "#000000",
            }}
          >
            <button
              style={{
                paddingLeft: "40px",
                paddingRight: "40px",
                paddingTop: "14px",
                paddingBottom: "14px",
                backgroundColor: "#602131",
                border: "none",
                borderRadius: "25px",
                cursor: "pointer",
                textAlign: "center",
                color: "#F7F3EF",
                fontSize: "14px",
                fontFamily: "Athelas",
                fontWeight: "bold",
                lineHeight: "15.60px",
                wordWrap: "break-word",
                marginBottom: "10px",
                marginTop: "10px",
              }}
              onClick={() => setIsIOS(false)}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
      )}

      {showPopup && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            backgroundColor: "transparent",
            width: "100%",
            zIndex: "10000",
          }}
        >
          <div
            style={{
              margin: "10px 10px",
              padding: "10px 10px",
              backgroundColor: "#FFFFFF",
              border: "none",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <IconButton
                size="small"
                aria-label="close"
                sx={{ cursor: "pointer", color: "black" }}
                onClick={() => setShowPopup(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </div>
            <div
              style={{
                width: "90%",
                textAlign: "center",
                fontFamily: "Avenir",
                fontSize: "14px",
                color: "#000000",
              }}
            >
              Para hacer más placentera tu visita guarda esta página.
            </div>
            <div
              style={{
                width: "90%",
                textAlign: "center",
                fontFamily: "Avenir",
                fontSize: "14px",
                color: "#000000",
              }}
            >
              <button
                style={{
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  paddingTop: "14px",
                  paddingBottom: "14px",
                  backgroundColor: "#602131",
                  border: "none",
                  borderRadius: "25px",
                  cursor: "pointer",
                  textAlign: "center",
                  color: "#F7F3EF",
                  fontSize: "14px",
                  fontFamily: "Athelas",
                  fontWeight: "bold",
                  lineHeight: "15.60px",
                  wordWrap: "break-word",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
                onClick={showAddToHomeScreenPrompt}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundImage:
            windowWidth >= 670
              ? `url(${headerBackGroundLarge})`
              : `url(${headerBackGround})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          position: "relative",
          height: "100vh",
          backgroundColor: "#F7F3EF",
        }}
      >
        <NavBarButton />
        <HeaderText />

        <MenuHeader />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F7F3EF",
        }}
      >
        <Participation />
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F7F3EF",
        }}
      >
        <Carrousel />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ComponentWithBackground />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F7F3EF",
        }}
      >
        <Footer />
      </div>
    </div>
  );
}

export default App;
