import React, { useState, useEffect } from 'react';
import Insignia from 'components/App/Insignia';
import Footer from 'components/App/Footer';

function LayoutInsignia() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);


    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            weight:'100%',
            height: '100vh',
            backgroundColor:'#FFFFFF',
        }
    };
    
    return (
       
            <div style={styles.container}>
                <Insignia />
            </div>         

    );
}

export default LayoutInsignia;
