import React, { useState, useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

import show_icon from "assets/images/show_icon.svg";
import hide_icon from "assets/images/hide_icon.svg";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import "styles/Register.module.css";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { API_URL } from "constants";

const SendData = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [showTooltip, setShowTooltip] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Estado para controlar la visibilidad de la contraseña
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Estado para mostrar/ocultar la confirmación de la contraseña
  const [formFields, setFormFields] = useState({});
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [inputType, setInputType] = useState("text");

  function NewlineText(props) {
    const text = props.text;
    const newText = text.split(";").map((str) => <p>{str}</p>);

    return newText;
  }
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const toggleInput = (event) => {
    if (inputType === "text") setInputType("date");
    setTimeout(() => {
      event.target.showPicker();
    }, 300);
  };

  const toggleInputBlur = () => {
    if (inputType === "date") setInputType("text");
  };

  // Función para alternar la visibilidad de la contraseña
  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "confirm_password") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };
  // Obtener los campos del formulario de la API
  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/view-tastingsendata`);
        const data = await response.json();
        setFormFields(data.tasting_send_data);
      } catch (error) {
        console.error("Error fetching form fields:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    
        const authToken = localStorage.getItem('authToken');
       
        // Realizar solicitud a la API utilizando el wine_code y el token
        fetch(`${API_URL}/shipping-data`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
        })
            .then(response => response.json())
            .then(data => {

                if(data.status !== "no data"){
                    
                  setFormData(data.status);

                }
            })
            .catch(error => console.error('Error:', error));
    
}, []);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#ffffffff",
      color: "#602131",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
    },
  }));

  // Estilos
  const styles = {
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
    },
    tabContainer: {
      width: "90%",
      marginTop: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 20,
    },
    tabLink: {
      flex: 1,
      textAlign: "center",
      color: "#C1A1A7",
      fontSize: 21,
      fontFamily: "Athelas",
      fontStyle: "italic",
      fontWeight: "bold",
      textDecoration: "none",
      padding: "10px 0",
      borderBottom: "1px solid #C1A1A7",
    },
    activeTabLink: {
      color: "#602131",
      borderBottom: "3px solid #602131",
    },
    container: {
      width: "85%",
      margin: "auto",
      marginTop: "15px",
      marginBottom: "50px",
    },
    socialButtons: {
      height: "80%",
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 20,
    },
    btn: {
      padding: "14px 30px",
      borderRadius: 50,
      border: "1px #602131 solid",
      color: "#602131",
      fontWeight: "bold",
      textAlign: "center",
      cursor: "pointer",
      display: "block",
      width: "80%",
      margin: "20px auto",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#ffffffff",
    },
    divider: {
      textAlign: "left",
      marginBottom: 20,
      color: "#602131",
      fontSize: "14px",
      fontStyle: "italic",
    },
    input: {
      width: "100%",
      padding: "10px 0",
      marginBottom: "20px",
      border: "none",
      borderBottom: "1px solid #C1A1A7",
      color: "#C1A1A7",
      background: "transparent",
      outline: "none",
      transition: "all 0.3s",
      fontFamily: "Inter",
      fontStyle: "italic",
      fontSize: "14px",
      //appearance: "none",
      "::placeholder": {
        // Modificar el color del placeholder
        color: "#602131",
      },
    },
    checkboxGroup: {
      display: "flex",
      alignItems: "center",
      marginTop: "10px",
      marginBottom: "25px",
      color: "#602131",
      position: "relative", // Added
    },

    customCheckbox: {
      display: "block",
      width: "20px",
      height: "20px",
      border: "1px solid #602131",
      borderRadius: "4px",
      marginRight: "10px",
      position: "relative",
      cursor: "pointer",
      appearance: "none",
      outline: "none",
      backgroundColor: "transparent",
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
    },

    btnHover: {
      backgroundColor: "#502021",
      color: "white",
    },
    tooltipIcon: {
      position: "relative",
      display: "inline-block",
      cursor: "pointer",
      marginLeft: "-25px", // Ajusta según sea necesario
      color: "#C1A1A7",
      userSelect: "none",
      fontSize: "12px",
    },
    tooltipText: {
      visibility: "hidden",
      width: "160px",
      backgroundColor: "#602131",
      color: "#fff",
      textAlign: "center",
      borderRadius: "6px",
      padding: "5px 0",
      position: "absolute",
      zIndex: 1,
      left: "-170%", // Ajusta según sea necesario
      bottom: "125%", // Ajusta según sea necesario
      opacity: 0,
      transition: "opacity 0.3s",
    },
    tooltipTextVisible: {
      visibility: "visible",
      opacity: 1,
    },
    // Nuevo estilo para el botón de visibilidad de la contraseña
    passwordVisibilityButton: {
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
      cursor: "pointer",
      zIndex: 1,
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      marginRight: "10px",
    },
    header:{

      fontSize:"18px",
      fontWeight: "bold"
    }
  };

  // Estados para las entradas
  const [formData, setFormData] = useState({
    shipping_name: "",
    zipcode: "",
    street: "",
    colony: "",
    town: "",
    state: "",
    indications: "",
    
  });

  const [message, setMessage] = useState(null);
  const [passwordsMatch, setPasswordsMatch] = useState(true); // Nuevo estado
  const [btnHover, setBtnHover] = useState(false);
  const [isOfAge, setIsOfAge] = useState(true);

  const handleMouseEnter = () => {
    setBtnHover(true);
  };

  const handleMouseLeave = () => {
    setBtnHover(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isOfAge) {
      setSnackbarMessage("Debes ser mayor de 18 años para registrarte.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    if (formData.password !== formData.password_confirmation) {
      setMessage("Las contraseñas no coinciden");
      setPasswordsMatch(false); // Las contraseñas no coinciden
      return;
    } else {
      setPasswordsMatch(true); // Las contraseñas coinciden
    }

    try {
      const authToken = localStorage.getItem('authToken');
      //const wineId = localStorage.getItem('wine_id');
      //const wineId = 1;

      //formData['wine_id'] = wineId;
      const response = await fetch(`${API_URL}/save-shipping-data`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      console.log("Respuesta del servidor:", data);

      if (response.ok) {
        
        setMessage("Datos Guardados exitosamente!");
        console.log("Ok:", response.ok);
        setSnackbarMessage("Datos Guardados exitosamente!");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        setMessage(data.message || "Error al registrar.");
        console.log("Err:", data.message);
        setSnackbarMessage(data.message);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    } catch (error) {
      setMessage("Error de red o servidor.");
      console.error("Error:", error);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const validateAge = (dob) => {
    const birthDate = new Date(dob);
    const currentDate = new Date();
    const age =
      currentDate.getFullYear() -
      birthDate.getFullYear() -
      (currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
        ? 1
        : 0);
    return age >= 18;
  };

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const newFormData = {
      ...formData,
      [e.target.id]: value,
    };
    setFormData(newFormData);
    if (e.target.id === "birthdate") {
      setIsOfAge(validateAge(e.target.value));
    }

    // Validar si las contraseñas coinciden cada vez que el usuario modifica las entradas
    if (e.target.id === "password" || e.target.id === "password_confirmation") {
      if (newFormData.password !== newFormData.password_confirmation) {
        setPasswordsMatch(false); // Las contraseñas no coinciden
      } else {
        setPasswordsMatch(true); // Las contraseñas coinciden
      }
    }
  };

  return (
    <div style={styles.body}>
      <div style={styles.tabContainer}>
        <span style={styles.header}>Datos de Envío</span>
      </div>

      <div style={styles.container}>
        {/*
                <div style={{ ...styles.socialButtons, justifyContent: 'space-between', marginBottom: 20 }}>
                    <div style={{ ...styles.btn, marginRight: '10px' }}>Google</div>
                    <div style={{ ...styles.btn, marginLeft: '10px' }}>Facebook</div>
                </div>
                */}

        <form onSubmit={handleSubmit}>
          {formFields &&
            Object.values(formFields).map((campo, index) => {
              if (
                campo.tag != "state" &&
                campo.tag != "extrafield" 
              ) {
                return (
                  <input
                    key={index}
                    type="text"
                    id={campo.tag}
                    value={formData[campo.tag]}
                    onChange={handleChange}
                    required
                    placeholder={
                      (campo.placeholder ? campo.placeholder : "") + "*"
                    }
                    style={styles.input}
                  />
                );
              }

              if (campo.tag == "state") {

                  return (
                    <select
                      id="state"
                      onChange={handleChange}
                      required
                      style={styles.input}
                      value={formData['state']} 
                      defaultValue={'DEFAULT'}  
                    >
                      <option value="" disabled selected>
                        {campo.placeholder ? campo.placeholder : ""}*
                      </option>
                      <option value="Aguascalientes">Aguascalientes</option>
                      <option value="Baja California">Baja California</option>
                      <option value="Baja California Sur">
                        Baja California Sur
                      </option>
                      <option value="Campeche">Campeche</option>
                      <option value="Chiapas">Chiapas</option>
                      <option value="Chihuahua">Chihuahua</option>
                      <option value="Ciudad de México">Ciudad de México</option>
                      <option value="Coahuila de Zaragoza">
                        Coahuila de Zaragoza
                      </option>
                      <option value="Colima">Colima</option>
                      <option value="Durango">Durango</option>
                      <option value="Estado de México">Estado de México</option>
                      <option value="Guanajuato">Guanajuato</option>
                      <option value="Guerrero">Guerrero</option>
                      <option value="Hidalgo">Hidalgo</option>
                      <option value="Jalisco">Jalisco</option>
                      <option value="Michoacán">Michoacán</option>
                      <option value="Morelos">Morelos</option>
                      <option value="Nayarit">Nayarit</option>
                      <option value="Nuevo León">Nuevo León</option>
                      <option value="Oaxaca">Oaxaca</option>
                      <option value="Puebla">Puebla</option>
                      <option value="Querétaro">Querétaro</option>
                      <option value="Quintana Roo">Quintana Roo</option>
                      <option value="San Luis Potosí">San Luis Potosí</option>
                      <option value="Sinaloa">Sinaloa</option>
                      <option value="Sonora">Sonora</option>
                      <option value="Tabasco">Tabasco</option>
                      <option value="Tamaulipas">Tamaulipas</option>
                      <option value="Tlaxcala">Tlaxcala</option>
                      <option value="Veracruz">Veracruz</option>
                      <option value="Yucatán">Yucatán</option>
                      <option value="Zacatecas">Zacatecas</option>
                    </select>
                  );
              }

              if (campo.tag == "extrafield") {
                return (
                  <input
                    key={index}
                    type="text"
                    id={campo.tag}
                    value={formData['extrafield']}
                    onChange={handleChange}
                    required
                    placeholder={campo.placeholder ? campo.placeholder : ""}
                    style={styles.input}
                  />
                );
              }
            })}

          <input
            type="submit"
            value="Guardar"
            style={
              btnHover
                ? { ...styles.btn, ...styles.btnHover, color: "white" }
                : styles.btn
            }
            className="register-btn"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </form>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ height: "100%", whiteSpace: "pre-wrap" }}
        >
          <NewlineText text={snackbarMessage} />
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SendData;
