import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import show_icon from "assets/images/show_icon.svg";
import hide_icon from "assets/images/hide_icon.svg";
import { API_URL } from 'constants';

const ChangePassword = () => {
    const { token } = useParams(); // Obtener el token de la URL
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = (field) => {
        if (field === 'password') {
            setShowPassword(!showPassword);
        } else if (field === 'confirm_password') {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        window.location.href = '/login';
    };

    const handleChange = (event, field) => {
        if (field === 'email') {
            setEmail(event.target.value);
        } else if (field === 'password') {
            setPassword(event.target.value);
        } else if (field === 'confirm_password') {
            setConfirmPassword(event.target.value);
        }
    };

    const styles = {
        body: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontFamily: 'Avenir',
            height: '100%',
            marginTop: '20%'
        },
        contentContainer: {
            width: '70%',
            textAlign: 'center',
            fontFamily: 'Athelas',
            color: '#602131',
            marginBottom: '10px'
    
        },
        title: {
            fontSize: '32px',
            width: '100%',
            fontStyle: 'italic',
            lineHeight: '38.40px',
            textAlign: 'center',
        },
        description: {
            marginTop:'15px',
            width: '100%',
            height: '36px',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '16.80px',
            fontFamily: 'Avenir',
            textAlign: 'center',
        },
        container: {
            width: '100%',
            marginBottom: '0px'
        },
        btn: {
            padding: '14px 30px',
            borderRadius: 50,
            border: '1px #602131 solid',
            color: '#602131',
            fontFamily:'Athelas',
            fontWeight:'bold',
            fontSize: '18px',
            textAlign: 'center',
            cursor: 'pointer',
            display: 'block',
            width: '80%',
            margin: '20px auto',
            marginTop: '50px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        input: {
            width: '100%',
            padding: '10px 0',
            marginTop:'25px',
            marginBottom: 15,
            border: 'none',
            borderBottom: '1px solid #C1A1A7',
            fontSize: 14,
            color: '#C1A1A7',
            background: 'transparent',
            outline: 'none',
            transition: 'all 0.3s',
            appearance: 'none',
        },
        snackbar: {
            position: 'fixed',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#ffffffff',
            color: '#602131',
            padding: '15px',
            borderRadius: '5px',
            zIndex: '999',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        passwordVisibilityButton: {
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            zIndex: 1,
            backgroundColor: 'transparent',
            border: 'none',
            outline: 'none',
            marginRight: '10px',
        },
    };

    return (
        <div style={styles.body}>
            <section style={styles.contentContainer}>
                <h1 style={styles.title}>Cuenta verificada</h1>
                <p style={styles.description}>
                Inicia sesión para comenzar con tus catas de vinos y vivir la experiencia.
                </p>
            </section>
            <div style={styles.container}>
                <form onSubmit={handleSubmit}>
                    
                    <div style={{ position: 'relative' }}>
                        
                    </div>
                    <input type="submit" value="Iniciar sesión" style={styles.btn} />
                </form>
            </div>
            {showSnackbar && (
                <div style={styles.snackbar}>
                    <div>{snackbarMessage}</div>
                </div>
            )}
        </div>
    );
};

export default ChangePassword;
