import React, { useState, useEffect } from 'react';

// Importando componentes para el diseño
import SingleNavbar from 'components/App/SingleNavbar';
import RegisterLogin from 'components/App/RegisterLogin';
import Footer from 'components/App/Footer';

// Importando imágenes utilizadas en el diseño
import headerBackGround from "assets/images/headerBackGround.png";
import headerBackGroundLarge from "assets/images/headerBackGroundLarge.png";
// Asegúrate de tener estas imágenes en la ruta especificada

/**
 * LayoutLoginRegister es un componente responsable de renderizar el diseño de las páginas de inicio de sesión y registro.
 */
function LayoutLoginRegister() {

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SingleNavbar />
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <RegisterLogin />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Footer />
      </div>
    </div>
  );



}

export default LayoutLoginRegister;
