import React, { useState, useEffect } from "react";
import SingleNavbar from "components/App/SingleNavbar";
import HeaderProfile from "components/App/HeaderProfile";
// Así debes importarlo si estás usando export default
import UserProfile from "components/App/UserRecompesas";

import Footer from "components/App/Footer";
import BackButton from "components/App/BackButton";
import NavbarWithStart from "components/App/NavbarWithStart";

function LayoutTerm() {
  const styles = {
    body: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      margin: 0,
      padding: 0,
      width: "100%",
    },
    container: {
      backgroundColor: "#ffffffff",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      margin: "65px",
      marginTop: "-25px",

      gap: "16px",
    },
    BackButton: {
      alignItems: "left",
      position: "absolute",
    },
  };
  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F7F3EF",
        }}
      >
        <NavbarWithStart/>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F7F3EF",
        }}
      >
        <UserProfile />
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F7F3EF",
        }}
      >
        <Footer />
      </div>
    </div>
  );
}

export default LayoutTerm;
