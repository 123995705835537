import React, { useState, useEffect } from 'react';
import SingleNavbar from 'components/App/SingleNavbar';
import CentroAyuda from 'components/App/MiEvaluacion';
import Footer from 'components/App/Footer';
import BackButton from 'components/App/BackButton'
import NavbarWithStart from 'components/App/NavbarWithStart';

function LayoutTerm() {

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <NavbarWithStart/>
      </div>

      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor:'#F7F3EF',
        }}
      >
        <CentroAyuda />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor:'#F7F3EF'
        }}
      >
        <Footer />
      </div>
    </div>
  );
}

export default LayoutTerm;
