import React, { useState, useEffect } from 'react';
import iconEnterAccount from "assets/images/iconEnterAccount.svg";
import iconStartExperience from "assets/images/iconStartExperience.svg";
import iconCreateAccount from "assets/images/iconCreateAccount.svg";
import iconHelpParticipation from "assets/images/iconHelpParticipation.svg";
import Modal from 'components/App/Modal'; // Importamos el componente de Modal desde su archivo separado
import { API_URL } from 'constants';
import { BASE_URL } from 'constants';

const styles = {
    body: {
        height: '100%',
        margin: 0,
        padding: 0,
        background: '#F7F3EF',/* Sets the background color of the app to a light beige */
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        margin: '45px auto',
        gap: '16px',
        backgroundColor: "#F5F1ED",
       
    },
    section: {
        alignItems: 'center',
        gap: '16px',
    },
    textStyles: {
        color: '#602131',
        fontFamily: 'Athelas',
        fontSize: '14px',
        wordWrap: 'break-word'
    },
    sectionTitle: {
        width: '241px',
        fontSize: '21px',
        fontFamily: 'Athelas',
        fontWeight: 'bold',
        fontStyle: 'normal',
        lineHeight: '25.20px'
    },
    sectionDesc: {
        width: '245px',
        fontFamily: 'Avenir',
        fontSize: '14px',
        fontWeight: 400,
        fontStyle: 'normal',
        lineHeight: '16.80px'
    },
    startButton: {
        width: '283px',
        height: '50px',
        cursor: 'pointer',
        padding: '14px 30px',
        border: '1px #602131 solid',
        borderRadius: '50px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        lineHeight: '21.60px',
        fontFamily: 'Athelas',
        fontWeight: 'bold',
        fontSize: '18px',
        color: '#602131',
        marginTop: '20px',
        backgroundColor: 'transparent',
    },
    btnHover: {
        backgroundColor: "#502021",
        color: "white",
      },
    divider: {
        height: '1px',
        backgroundColor: '#C1A1A7',
        border:'none',
        marginTop:'20px',
        marginBottom:'20px',
    },
    header: {
        width: '100%',
        fontFamily: 'Athelas',
        fontWeight: 'bold',
        fontSize: '32px',
        fontStyle: 'italic',
        lineHeight: '38.40px',
        color: '#602131',
        alignItems: 'left',
        justifyContent: 'left',
        marginBottom:'25px'
    }
};


const Participation = () => {
    const [data, setData] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [description, setDescription] = React.useState("");
    const [btnHover, setBtnHover] = React.useState(false);

    const handleMouseEnter = () => {
        setBtnHover(true);
      };
    
      const handleMouseLeave = () => {
        setBtnHover(false);
      };

    useEffect(() => {
        fetch(`${API_URL}/view-home`, {
            method: 'GET',
            headers: {
                'Cookie': 'XSRF-TOKEN=...; entre_copas_session=...'
                // Asegúrate de completar la cookie correctamente
            }
        })
        .then(response => response.json())
        .then(data => setData(data))
        .catch(error => console.error('Error:', error));
    }, []);

    if (!data) {
        return <div>Cargando...</div>;
    }

    const steps = data.como_participar.steps.map((step, index) => (
        <div key={index} style={{ ...styles.section, alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px'}}>
                <img width="78" height="70" style={{objectFit:'contain'}} src={`${BASE_URL}/${step.icon_url}`} alt="Imagen de participaciones"/>
                <div>
                    <div style={{ ...styles.textStyles, ...styles.sectionTitle }}>{step.title}</div>
                    <div style={{ ...styles.textStyles, ...styles.sectionDesc }}>{step.description}</div>
                </div>
            </div>
            {
                (index < 2) && <hr style={styles.divider} />   
            }         
        </div>
    ));
    
    const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    return (
        <div id="section-below" style={styles.container}>
            <div style={styles.header}>{data.como_participar.title}</div>
            {steps}
            <button style={
              btnHover
                ? { ...styles.startButton, ...styles.btnHover, color: "white" }
                : styles.startButton
            } onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleOpen} >Comenzar</button>
            <Modal open={open} handleClose={handleClose} description={description} />

        </div>
    );
};

export default Participation;
