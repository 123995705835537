import React, { useState, useEffect } from 'react';
import bloqueadoIcon from "assets/images/bloqueadoIcon.svg";
import desbloqueadoIcon from "assets/images/desloqueadoIcon.svg";
import { BASE_URL, API_URL } from 'constants';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '350px'
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
    fontStyle: 'italic',
    marginBottom: '10px',
  },
  subtitle: {
    fontSize: '14px',
    marginBottom: '20px',
  },
  cardContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: '20px',
  },
  imageContainer: {
    width: '50%',
    position: 'relative',
  },
  image: {
    width: '80px',
    height: '80px',
    marginLeft: '25px',
    marginTop: '5px',
    borderRadius: '50%'
  },
  icon: {
    position: 'absolute',
    bottom: "13vw",
    right: "22vw",
    width: '25px',
    height: '25px',
  },
  contentContainer: {
    width: '50%',
    padding: '10px',
  },
  rewardTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '5px',
    marginLeft: "-70px",
  },
  rewardDescription: {
    fontSize: '14px',
    marginLeft: "-70px",
  },
};

const UserProfile = () => {
  const [rewards, setRewards] = useState({
    title: '',
    subtitle: '',
    list_rewards: [],
  });
  const [userProfile, setUserProfile] = useState({
    avatar: '',
    badgeIcon: '',
  });

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      console.error('No se encontró el token de autenticación en el localStorage');
      return;
    }

    fetch(`${API_URL}/view-rewards`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error al obtener las recompensas.');
        }
        return response.json();
      })
      .then(data => {
        setRewards(data.rewards);
        console.log('Recompensas obtenidas:', data.rewards);
      })
      .catch(error => console.error('Error al obtener las recompensas:', error));

    const userAvatarUrl = `${BASE_URL}`;
    fetch(`${API_URL}/get-user-profile`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error al obtener el perfil del usuario.');
        }
        return response.json();
      })
      .then(data => {
        setUserProfile({
          avatar: userAvatarUrl + data.avatar,
          badgeIcon: userAvatarUrl + data.badgeIcon,
        });
        console.log('Perfil del usuario obtenido:', data);
      })
      .catch(error => console.error('Error al obtener el perfil del usuario:', error));
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>{rewards.title}</h1>
      <p style={styles.subtitle}>{rewards.subtitle}</p>
      {rewards.list_rewards.map((reward, index) => (
        <div key={index} style={{ ...styles.cardContainer, backgroundColor: index % 2 === 0 ? '#F0ECE7' : 'transparent' }}>
          <div style={styles.imageContainer}>
            <img style={styles.image} src={`${BASE_URL}/${reward.image}`} alt="Reward" />
            <img style={styles.icon} src={reward.active ? desbloqueadoIcon : bloqueadoIcon} alt="Icon" />
          </div>
          <div style={styles.contentContainer}>
            <div style={styles.rewardTitle}>{reward.title}</div>
            <div style={styles.rewardDescription}>{reward.description}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserProfile;
