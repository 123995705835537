import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import show_icon from "assets/images/show_icon.svg";
import hide_icon from "assets/images/hide_icon.svg";
import { API_URL } from 'constants';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const ChangePassword = () => {
    const { token } = useParams(); // Obtener el token de la URL
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true); // Nuevo estado
    const [btnHover, setBtnHover] = React.useState(false);
    const navigate = useNavigate();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const [formData, setFormData] = useState({
       
        token: token,
        email: "",
        password: "",
        password_confirmation: "",
        
      });

    const handleMouseEnter = () => {
        setBtnHover(true);
      };
    
      const handleMouseLeave = () => {
        setBtnHover(false);
      };

    const togglePasswordVisibility = (field) => {
        if (field === 'password') {
            setShowPassword(!showPassword);
        } else if (field === 'confirm_password') {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
    
        setOpenSnackbar(false);
      };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(`${API_URL}/reset`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            console.log(data);

            if (response.ok) {
                setSnackbarMessage(data.message);
                setSnackbarSeverity("success");
                setOpenSnackbar(true); // Mostrar el Snackbar
                setTimeout(() => {
                    navigate("/login");
                  }, 3000);
            } else {
                setSnackbarMessage(data.message);
                setSnackbarSeverity("error");
                setOpenSnackbar(true); // Mostrar el Snackbar
                
            }
        } catch (error) {
            console.error('Error:', error);
            setSnackbarMessage('Error: Inténtalo de nuevo más tarde');
            setSnackbarSeverity("error");
            setOpenSnackbar(true); // Mostrar el Snackbar
        }
    };

    const handleChange = (e) => {
        const value =
          e.target.type === "checkbox" ? e.target.checked : e.target.value;
        const newFormData = {
          ...formData,
          [e.target.id]: value,
        };
        setFormData(newFormData);

    
        // Validar si las contraseñas coinciden cada vez que el usuario modifica las entradas
        if (e.target.id === "password" || e.target.id === "password_confirmation") {
          if (newFormData.password !== newFormData.password_confirmation) {
            setPasswordsMatch(false); // Las contraseñas no coinciden
          } else {
            setPasswordsMatch(true); // Las contraseñas coinciden
          }
        }
      };

    const styles = {
        body: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontFamily: 'Avenir',
            height: '100%',
        },
        contentContainer: {
            width: '90%',
            textAlign: 'center',
            fontFamily: 'Athelas',
            color: '#602131',
    
        },
        title: {
            fontSize: '32px',
            width: '100%',
            fontStyle: 'italic',
            lineHeight: '38.40px',
            textAlign: 'center',
        },
        description: {
            marginTop:'15px',
            width: '100%',
            height: '36px',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '16.80px',
            fontFamily: 'Avenir',
            textAlign: 'center',
        },
        container: {
            width: '90%',
            marginBottom: '0px'
        },
        btn: {
            padding: '14px 30px',
            borderRadius: 50,
            border: '1px #602131 solid',
            color: '#602131',
            fontFamily:'Athelas',
            fontWeight:'bold',
            fontSize: '18px',
            textAlign: 'center',
            cursor: 'pointer',
            display: 'block',
            width: '80%',
            margin: '20px auto',
            marginTop: '50px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        input: {
            width: '100%',
            padding: '10px 0',
            marginTop:'25px',
            marginBottom: 15,
            border: 'none',
            borderBottom: '1px solid #C1A1A7',
            fontSize: 14,
            color: '#C1A1A7',
            background: 'transparent',
            outline: 'none',
            transition: 'all 0.3s',
            appearance: 'none',
        },
        snackbar: {
            position: 'fixed',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#ffffffff',
            color: '#602131',
            padding: '15px',
            borderRadius: '5px',
            zIndex: '999',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        passwordVisibilityButton: {
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            zIndex: 1,
            backgroundColor: 'transparent',
            border: 'none',
            outline: 'none',
            marginRight: '10px',
        },
        btnHover: {
            backgroundColor: "#502021",
            color: "white",
          },
    };

    return (
        <div style={styles.body}>
            <section style={styles.contentContainer}>
                <h1 style={styles.title}>Nueva contraseña</h1>
                <p style={styles.description}>
                    Ingresa tu nueva contraseña y no la olvides, ya que es tu acceso para seguir destapando más vinos.
                </p>
            </section>
            <div style={styles.container}>
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        id="email"
                        required
                        placeholder="Correo*"
                        style={styles.input}
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <div style={{ position: 'relative' }}>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                            placeholder="Contraseña*"
                            style={styles.input}
                        />
                        <button
                            type="button"
                            onClick={() => togglePasswordVisibility('password')}
                            style={styles.passwordVisibilityButton}
                        >
                            <img src={showPassword ? hide_icon : show_icon} alt={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'} style={{ width: '15px' }} />
                        </button>
                    </div>
                    <div style={{ position: 'relative' }}>
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="password_confirmation"
                            value={formData.password_confirmation}
                            onChange={handleChange}
                            required
                            placeholder="Confirmar Contraseña*"
                            style={styles.input}
                        />
                        <button
                            type="button"
                            onClick={() => togglePasswordVisibility('confirm_password')}
                            style={styles.passwordVisibilityButton}
                        >
                            <img src={showConfirmPassword ? hide_icon : show_icon} alt={showConfirmPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'} style={{ width: '15px' }} />
                        </button>
                        {!passwordsMatch && (
                        <span style={{ color: "red", fontSize: 12 }}>
                        Las contraseñas no coinciden
                        </span>
          )}
                    </div>
                    <input type="hidden" id="token" value={formData.token}/>
                    <input onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} type="submit" value="Cambiar contraseña" style={ btnHover ? { ...styles.btn, ...styles.btnHover, color: "white" } : styles.btn } />
                </form>
                <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ height: "100%", whiteSpace: "pre-wrap" }}
        >
          {snackbarMessage} 
        </Alert>
      </Snackbar>
            </div>
        </div>
    );
};

export default ChangePassword;
