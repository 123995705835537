import React, { useState, useEffect } from 'react';
import fondoRecompesas from "assets/images/fondoRecompesas.svg";
import ComponentWithBackground from 'components/App/ComponentWithBackground';
import { BASE_URL, API_URL } from 'constants';

const Carrousel = () => {
    const [rewardsData, setRewardsData] = useState([]);
    const [currentRewardIndex, setCurrentRewardIndex] = useState(0);
    const [titlePrincipal, setTitlePrincipal] = useState("");

    useEffect(() => {
        fetch(`${API_URL}/view-home`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                setTitlePrincipal(data.recompensas?.title || ""); // Modificación aquí

                const rewards = data.recompensas?.rewards || [];
                const modifiedRewards = rewards.map(reward => ({
                    title: reward.title,

                    image: reward.image.startsWith('http') ? reward.image : `${BASE_URL}/${reward.image}`,
                }));
                setRewardsData(modifiedRewards);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const styles = {
        container: {
            position: 'relative',
            width: '100%',
            height: '500px',
            backgroundImage: `url(${fondoRecompesas})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            textAlign: 'center',
        },
        carousel: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
        },
        mainImage: {
            width: '200px',
            height: '200px',
            zIndex: 2,
            borderTopLeftRadius: '200px',
            borderTopRightRadius: '200px',
            marginTop: '20px', // Reducido para compensar el desplazamiento hacia arriba

        },
        roundedBox: {
            width: '225px',
            height: '225px',
            position: 'absolute',
            top: '5px', // Aumentado para centrar el cuadro
            left: '50%', // Para centrar horizontalmente
            transform: 'translateX(-50%)', // Para centrar horizontalmente
            borderTopLeftRadius: '200px',
            borderTopRightRadius: '200px',
            border: '1px rgba(193, 161, 167, 0.25) solid',
            zIndex: 1,

        },
        title: {
            color: '#ffffffff',
            fontSize: '25px',
            fontFamily: 'Athelas',
            fontStyle: 'italic',
            fontWeight: 'bold',
            lineHeight: '38.40px',
            wordWrap: 'break-word',
            marginTop: '10px',
        },
        titlePrincipal: {
            color: '#ffffffff',
            fontSize: '32px',
            fontFamily: 'Athelas, serif',
            fontStyle: 'italic',
            fontWeight: 600,
            lineHeight: '38.40px',
            wordWrap: 'break-word',
            paddingTop: '30px', // Padding superior añadido

        },
        button: {
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            fontSize: '32px',
            color: '#ffffffff',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 3,
        },
        prevButton: {
            left: '0',
            marginLeft: '15px',
        },
        nextButton: {
            right: '0',
            marginRight: '15px',
        },
        dotsContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            zIndex: 4,
        },
        dot: {
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: '#C1A1A7', // Color de puntos no activos
            margin: '0 5px',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
        },
        activeDot: {
            width: '12px',
            height: '12px',
            backgroundColor: '#F5F1ED', // Color de punto activo
        },
    };

    const goToReward = (index) => {
        setCurrentRewardIndex(index);
    };

    const goToPreviousReward = () => {
        setCurrentRewardIndex(prevIndex => (prevIndex === 0 ? rewardsData.length - 1 : prevIndex - 1));
    };

    const goToNextReward = () => {
        setCurrentRewardIndex(prevIndex => (prevIndex === rewardsData.length - 1 ? 0 : prevIndex + 1));
    };

    return (
            <div style={styles.container}>
                <h1 style={styles.titlePrincipal}>{titlePrincipal}</h1> {/* Modificación aquí */}               
                <div style={styles.carousel}>
                    <img
                        src={rewardsData.length > 0 ? rewardsData[currentRewardIndex].image : null}
                        alt="Recompensas"
                        style={styles.mainImage}
                    />
                    <div style={styles.roundedBox}></div>
                </div>
                <div style={styles.title}>{rewardsData.length > 0 && rewardsData[currentRewardIndex].title}</div>

                <div style={styles.dotsContainer}>
                    {rewardsData.map((reward, index) => (
                        <div
                            key={index}
                            style={{
                                ...styles.dot,
                                ...(index === currentRewardIndex ? styles.activeDot : {}),
                            }}
                            onClick={() => goToReward(index)}
                        ></div>
                    ))}
                </div>
                <button onClick={goToPreviousReward} style={{ ...styles.button, ...styles.prevButton }}>←</button>
                <button onClick={goToNextReward} style={{ ...styles.button, ...styles.nextButton }}>→</button>
            </div>

    );
};

export default Carrousel;
