import React, { useEffect, useState } from 'react';
import { BASE_URL, API_URL } from 'constants';

const VideoComponent = () => {
    const [videoUrl, setVideoUrl] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVideoUrl = async () => {
            try {
                const wineId = localStorage.getItem('wine_id');                                 
                const response = await fetch(`${API_URL}/get-video-tutorial`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'User-Agent': 'insomnia/2023.5.8'
                    },
                    body: JSON.stringify({ wine_id: wineId })
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch video URL');
                }
                const data = await response.json();
                const videoUrl =  `${BASE_URL}/${data.video_tutorial}`;
                setVideoUrl(videoUrl);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchVideoUrl();
    }, []);

    const handleSkip = () => {
        window.location.href = '/evaluacion_gustativa';
    };

    const styles = {
        container: {
            position: 'relative',
            width: '100vw',
            height: '100vh',
            overflow: 'hidden',
        },
        video: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        skipButton: {
            position: 'absolute',
            top: '20px',
            right: '20px',
            background: 'none',
            border: 'none',
            color: 'white',
            fontSize: '16px',
            cursor: 'pointer',
        },
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div style={styles.container}>
            {videoUrl ? (
                <video style={styles.video} controls>
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            ) : (
                <div>Loading video...</div>
            )}
            <button style={styles.skipButton} onClick={handleSkip}>Omitir</button>
        </div>
    );
};

export default VideoComponent;
