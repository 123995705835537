import React, { useState, useEffect, useRef } from 'react';
import { BASE_URL, API_URL } from 'constants';

const KnowWines = () => {
  const [winesData, setWinesData] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const carouselRef = useRef(null);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');

    if (!authToken) {
      console.error('No se encontró el token de autenticación en el localStorage');
      return;
    }

    // Aquí realizarías la llamada a la API para obtener los datos de los vinos
    fetch(`${API_URL}/view-userprofile`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => {
      setWinesData(data.wines);
    })
    .catch(error => console.error('Error fetching wines data:', error));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(prevSlide => (prevSlide + 1) % (winesData?.slider_wines.length || 1));
    }, 7000); // Cambiar de slide cada 2 segundos

    return () => clearInterval(interval);
  }, [winesData]);

  const handleTouchStart = (event) => {
    setTouchStart(event.touches[0].clientX);
  };

  const handleTouchMove = (event) => {
    setTouchEnd(event.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 100) {
      setCurrentSlide(prevSlide => (prevSlide + 1) % (winesData?.slider_wines.length || 1));
    }

    if (touchStart - touchEnd < -100) {
      setCurrentSlide(prevSlide => (prevSlide - 1 + (winesData?.slider_wines.length || 1)) % (winesData?.slider_wines.length || 1));
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      {winesData && (
        <div>
          {/* Título */}
          <h2 style={{ textAlign: 'center', fontSize: '33px', fontWeight: 'bold', padding: '5px 0' }}>{winesData.title_wine}</h2>
          {/* Descripción */}
          <p style={{ textAlign: 'center', fontSize: '21px' }}>{winesData.description}</p>
          {/* Carrusel */}
          <div
            ref={carouselRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{
              width: '80%',
              margin: '0 auto',
              overflow: 'hidden',
              marginTop: '20px',
              position: 'relative',
            }}
          >
            <div
              style={{
                display: 'flex',
                transition: 'transform 0.5s ease',
                transform: `translateX(-${currentSlide * 100}%)`,
              }}
            >
              {winesData.slider_wines.map((slide, index) => (
                <div
                  key={index}
                  style={{
                    flex: '0 0 auto',
                    width: '100%',
                    marginRight: '0px',
                    borderRadius: '5px',
                  }}
                >
                  <img
                    src={BASE_URL + "/" + slide.image}
                    alt={`Slide ${index}`}
                    style={{
                      width: '130px',
                      height: '350px',
                    }}
                  />
                  <div style={{display: 'grid',
                              gridTemplateColumns: '3.5fr 1.2fr',
                              gridGap: '10px',
                              gridAutoRows: 'minmax(5px, auto)',
                              position:'relative',
                              marginTop: '-100px',
                              marginLeft: '37%',
                              borderTop: '1px solid #c1a1a7',
                              backgroundColor: '#F7F3EF',
                              width: '130px'
                              }}
                >
                    <div style={{ gridColumn: '1 / span 2',
                                  textAlign: 'left'
                                }} 
                    >
                      <span style={{fontSize: '12px'}}>País: {slide.country}</span> <br />
                      <span style={{fontSize: '12px'}}>Vino: {slide.wine_type}</span> <br />
                      <span style={{ fontWeight: 'bold', color: '#602131', fontSize: '12px'}}>{slide.title}</span> <br />
                      <span style={{fontSize: '10px'}}>Región: {slide.zone}</span>
                      <span style={{fontSize: '10px', display:'block'}}>Bodega: {slide.store}</span>
                      &nbsp;
                    </div>
                    <div style={{ gridColumn: '1 / span 2',
                                  textAlign: 'left'
                                }} 
                    >
                </div>
                </div>
                </div>
              ))}
            </div>
          </div>
          {/* Puntos de botón */}
          <div style={{ marginTop: '20px' }}>
            {winesData.slider_wines.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentSlide(index)}
                style={{
                  width: '12px',
                  height: '12px',
                  margin: '0 5px',
                  borderRadius: '6px',
                  backgroundColor: currentSlide === index ? '#602131' : '#C1A1A7',
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default KnowWines;
