import React from "react";
import logo from "assets/images/Logo-blanco.svg";

const styles = {
  page: {
    width: "100%",
    maxHeight: "100vh",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "flex",
  },

  img_wrapper_l: {
    height: "100%",
    width: "100%",
    position: "absolute",
    float: "left",
    marginTop: "0",
  },

  img_wrapper_r: {
    height: "100%",
    width: "100%",
    position: "absolute",
    float: "right",
    marginTop: "0",
  },

  img: {
    width: "100%",
    maxWidth: "900px",
    maxHeight: "500px",
    overflow: "hidden",
  },

  img2: {
    width: "20%",
    maxWidth: "900px",
    maxHeight: "500px",
    overflow: "hidden",
    marginLeft: "40.5%",
    marginTop: "4.7%",
  },
};

const ImageComponent = ({imageCover}) => {


  return (
    <div style={styles.page}>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <img
          src={imageCover}
          alt="Preguntas Header"
          style={styles.img}
        />
      </div>
      <div style={styles.img_wrapper_r}>
        <a href="/">
          <img src={logo} alt="logo" style={styles.img2} />
        </a>
      </div>
    </div>
  );
};

export default ImageComponent;