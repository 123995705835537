import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackProfileButton = () => {
    const navigate = useNavigate();

    const styles = {
        color: '#602131',
        cursor: 'pointer',
        fontSize: '14px',
        fontFamily: 'Avenir',
        fontWeight: 400,
        lineHeight: '16.80px',
        wordWrap: 'break-word',
        textDecoration: 'none',  // Para eliminar el subrayado de los enlaces
        padding: 0,             // Asegura que no haya padding
        textAlign: 'left',
        position: 'relative',
        left: '0px',
        marginRight: 'auto',
        marginLeft: '20px'
    };

    const handleBackClick = (e) => {
        e.preventDefault();
        navigate('/perfil-code');   // Navega hacia atrás
    };
    return (
        <a  style={styles} onClick={handleBackClick}>
            &lt;&lt; Regresar
        </a>
    );
}

export default BackProfileButton;
