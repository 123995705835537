import React, { useState, useEffect } from 'react';
import SingleNavbar from 'components/App/SingleNavbar';
import ChangePassword from 'components/App/DataProfile';
import Footer from 'components/App/Footer';
import MenuRecompesa from 'components/App/MenuRecompesa';

import headerBackGround from "assets/images/headerBackGround.png";
import headerBackGroundLarge from "assets/images/headerBackGroundLarge.png";
  // Asegúrate de tener esta imagen

function LayoutLoginRegister() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);


  const styles = {
    body: {
        height: '100%',
        margin: 0,
        padding: 0,
        width: "100%",
        backgroundColor: "#ffffff", // Fondo blanco
    },
    container: {

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      margin: '65px',
      marginBottom: '-65px',

      gap: '16px'
  },
  footer:{

  }

};
  return (
    <div>
       <SingleNavbar/>
      <div style={styles.container}>  
      <ChangePassword/>

      </div>
     <MenuRecompesa/>
  <div style={styles.footer}>
  <Footer/>

  </div>
    </div>
  );
}

export default LayoutLoginRegister;
