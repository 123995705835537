import React from 'react';
import { Link } from 'react-router-dom';

const ListItem = ({ title, to, isLast, handleLogout }) => {
  const handleClick = () => {
    if (to === '/logout') {
      handleLogout();
    }
  };

  return (
    <div style={{ ...styles.listItem, borderBottom: isLast ? 'none' : '1px solid #D0CCC8' }}>
      {to ? (
        <Link to={to} style={styles.link} onClick={handleClick}>
          <span style={styles.title}>{title}</span>
        </Link>
      ) : (
        <span style={{ ...styles.title, fontWeight: 'bold' }}>{title}</span>
      )}
      {!isLast && <span style={styles.arrow}>&gt;</span>}
    </div>
  );
};

const List = () => {
  const handleLogout = () => {
    localStorage.removeItem('userPassword');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('authToken');
    localStorage.removeItem('userData');
  };

  return (
    <div style={styles.container}>
      <ListItem title="Mis evaluaciones" to="/mi-evaluacion" />
      <ListItem title="Cambiar contraseña" to="/nueva-password" />
      <ListItem title="Datos de envío" to="/datos-envio" />
      <ListItem
        title="Cerrar sesión"
        to="/"
        handleLogout={handleLogout}
        isLast
        style={styles.cerrarSesion}
      />
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#F7F3EF',
    padding: '20px',
    marginTop: '-50px'
  },
  cerrarSesion: { fontWeight: 900, fontSize: '15px' },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
  },
  title: {
    fontSize: '14px',
  },
  arrow: {
    fontSize: '35px',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
};

export default List;
