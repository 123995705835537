import React, { useState, useEffect } from "react";
import KnowWines from "components/App/KnowWines";
import SliderPromotion from "components/App/SliderPromotion";
import Recompesas from "components/App/Recompesas";
import BackButton from "components/App/BackButton";

import ProfileBackgroundHeader from "assets/images/ProfileBackgroundHeader.svg";
import { API_URL, BASE_URL } from "constants";
import BackProfileButton from "./BackProfileButton";

const styles = {
  container: {
    maxWidth: "100%",
    margin: "0 auto",
    position: "relative",
    backgroundImage: `url(${ProfileBackgroundHeader})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    padding: "25px 0px", // Agregando padding-bottom
    marginTop: "0px", // Margen superior
    width: "100vw", // Ancho al 100% del viewport
    overflowX: "hidden", // Ocultar scroll horizontal si hay contenido que excede el ancho
  },
  userInfo: {
    textAlign: "center",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    height: "100%",
  },
  userName: {
    color: "#602131",
    fontFamily: "Athelas",
    fontWeight: "700",
    fontSize: "20px", // 10% más pequeño
  },
  userLevel: {
    color: "#602131",
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "16px", // 10% más pequeño
  },
  userAvatar: {
    width: "15vw", // 10% más pequeño
    height: "15vw", // 10% más pequeño
    position: "relative",
    marginLeft: "20px",
  },
  avatarImg: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    display: "block",
    margin: "auto",
    marginLeft: "5px",
  },
  badge: {
    width: "7.5vw",
    height: "7.5vw",
    position: "absolute",
    bottom: "8vw",
    right: "-2vw",
  },
  badgeIcon: {
    width: "72%", // 10% menos
    height: "72%", // 10% menos
    borderRadius: "50%",
    border: "0.45px solid #602131", // 10% menos
    position: "absolute",
    top: "14%", // 10% menos
    left: "14%", // 10% menos
  },
};

export default function UserProfile() {
  const [userProfile, setUserProfile] = useState({
    name: "",
    level: 0,
    avatar: "https://via.placeholder.com/60x60",
    badgeIcon: "https://via.placeholder.com/24x24",
  });

  useEffect(() => {
    const userEmail = localStorage.getItem("userEmail");
    const userPassword = localStorage.getItem("userPassword");
    if (!userEmail || !userPassword) {
      console.error(
        "No se encontraron las credenciales de usuario en el localStorage."
      );
      return;
    }

    fetch(`${API_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: userEmail,
        password: userPassword,
        terms: "1",
        offers: "1",
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al iniciar sesión.");
        }
        return response.json();
      })
      .then((loginData) => {
        setUserProfile({
          name: loginData.user.name,
          level: loginData.user.level,
          avatar: loginData.user.photo
            ? `${BASE_URL}/${loginData.user.photo}`
            : "https://via.placeholder.com/60x60",
          badgeIcon: loginData.user.level_icon
            ? `${BASE_URL}/${loginData.user.level_icon}`
            : "https://via.placeholder.com/24x24",
        });
        console.log("Nombre del usuario:", loginData.user.name);
        console.log("Nombre del usuario:", loginData.user.name);
      })
      .catch((error) => console.error("Error al iniciar sesión:", error));
  }, []);

  return (
    <div>
      <div style={{marginTop:'10px', marginBottom:'10px'}}>
        <BackProfileButton/>
      </div>

      <div style={styles.container}>
        <div style={styles.userInfo}>
          <div style={styles.userAvatar}>
            <img
              style={styles.avatarImg}
              src={userProfile.avatar}
              alt={userProfile.name}
            />
            <div style={styles.badge}>
              <img
                style={styles.badgeIcon}
                src={userProfile.badgeIcon}
                alt="Avatar"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              position: "relative",
              maxHeight: "100%",
              marginLeft: "20px",
            }}
          >
            <div style={styles.userName}>{userProfile.name}</div>
            <div style={styles.userLevel}>Nivel: {userProfile.level}</div>
          </div>
        </div>
      </div>
      <Recompesas />
    </div>
  );
}
