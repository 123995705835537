import React from 'react';
import sinCuenta from 'assets/images/SinCuenta.svg';

const RegisterLoginPrompt = () => {
    const handleRegister = () => {
        window.location.href = '/registro';
    };

    const handleLogin = () => {
        window.location.href = '/login';
    };

    const styles = {
        container: {
            textAlign: 'center',
            marginBottom: '20px',
        },
        image: {
            width: '70%',
            height: '250px',
            maxWidth: '300px',
            marginBottom: '20px',
            marginTop: '100px',
        },
        title: {
            fontSize: '32px',
            fontWeight: 'bold',
            fontStyle: 'italic',
            marginBottom: '10px',
        },
        description: {
            fontSize: '14px',
            marginBottom: '20px',
        },
        containerButtons: {
            height: '80%',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px',
        },
        btn: {
            padding: '14px 10px',
            borderRadius: '50px',
            border: '1px #602131 solid',
            color: '#602131',
            fontWeight: 'bold',
            textAlign: 'center',
            cursor: 'pointer',
            display: 'block',
            width: '80%',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            transition: 'background-color 0.3s, color 0.3s',
        },        
    };

    return (
        <div style={styles.container}>
            <img src={sinCuenta} alt="No tienes cuenta" style={styles.image} />
            <div style={styles.title}>¿Aún no eres miembro?</div>
            <div style={styles.description}>Inicia sesión o regístrate para comenzar con la experiencia.</div>
            <div style={styles.containerButtons}>
                <button
                    style={{ ...styles.btn, marginRight: '10px' }}
                    onClick={handleRegister}
                    onMouseEnter={(e) => { e.target.style.backgroundColor = '#602131'; e.target.style.color = '#fff'; }}
                    onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent'; e.target.style.color = '#602131'; }}
                >
                    Registrarse
                </button>
                <button
                    style={{ ...styles.btn, marginLeft: '10px' }}
                    onClick={handleLogin}
                    onMouseEnter={(e) => { e.target.style.backgroundColor = '#602131'; e.target.style.color = '#fff'; }}
                    onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent'; e.target.style.color = '#602131'; }}
                >
                    Iniciar sesión
                </button>
            </div>
        </div>
    );
};

export default RegisterLoginPrompt;
