import React, { useState, useEffect } from "react";
import KnowWines from "components/App/KnowWines";
import SliderPromotion from "components/App/SliderPromotion";
import Modal from "components/App/Modal"; // Importamos el componente de Modal desde su archivo separado

import ProfileBackgroundHeader from "assets/images/ProfileBackgroundHeader.svg";
import GenericUserPhoto from "assets/images/user.svg";
import NoCataAvailable from "assets/images/nocata.svg";
import { BASE_URL, API_URL } from "constants";
import plusIcon from "assets/images/icons8-plus.svg";

const styles = {
  container: {
    width: "100%",
    margin: "0 auto",
    position: "relative",
    backgroundColor: "transparent",
    backgroundImage: `url(${ProfileBackgroundHeader})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingBottom: "25px", // Agregando padding-bottom
  },
  userInfo: {
    textAlign: "center",
    position: "relative",
  },
  userName: {
    color: "#602131",
    fontFamily: "Athelas",
    fontWeight: "700",
    fontSize: "5.6vw",
  },
  userLevel: {
    color: "#602131",
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "3.7vw",
  },
  userAvatar: {
    width: "17vw",
    height: "17vw",
    margin: "0 auto",
    position: "relative",
    paddingTop: "20px",
  },
  avatarImg: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    display: "block",
    margin: "auto",
    objectFit: "cover",
  },
  badge: {
    width: "7.0vw",
    height: "7.0vw",
    position: "absolute",
    bottom: "-1vw",
    right: "-1vw",
  },
  badgeTop: {
    width: "7.5vw",
    height: "7.5vw",
    position: "absolute",
    bottom: "8vw",
    right: "-2vw",
  },
  badgeBackground: {
    width: "100%",
    height: "100%",
    background: "white",
    borderRadius: "50%",
    position: "absolute",
  },
  badgeIcon: {
    width: "80%",
    height: "80%",
    borderRadius: "50%",
    border: "transparent",
    position: "absolute",
    top: "10%",
    left: "10%",
  },
  ctaButton: {
    width: "75%",
    height: "12vw",
    margin: "2vw auto",
    background: "#602131",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
  },
  circularBtn: {
    background: "none",
    border: "none",
    marginLeft: 0,
  },
  ctaText: {
    textAlign: "center",
    color: "#F7F3EF",
    fontFamily: "Athelas",
    fontSize: "18px",
    fontWeight: "700",
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    zIndex: 1,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgba(0,0,0,0.4)",
  },
  modalContent: {
    backgroundColor: "#fefefe",
    padding: "20px",
    border: "1px solid #888",
    width: "80%",
    borderRadius: "10px",
    maxWidth: "500px", // Ajusta el ancho máximo según sea necesario
  },
  closeModal: {
    color: "#aaa",
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "40px",
    cursor: "pointer",
    zIndex: 1,
  },
};

export default function UserProfile() {
  const [userProfile, setUserProfile] = useState({
    name: "",
    level: 0,
    avatar: "https://via.placeholder.com/60x60",
    badgeIcon: "https://via.placeholder.com/24x24",
  });
  const [open, setOpen] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [codigoWine, setCodigoWine] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const loadProfile = () => {
    const userEmail = localStorage.getItem("userEmail");
    const userPassword = localStorage.getItem("userPassword");
    if (!userEmail || !userPassword) {
      console.error(
        "No se encontraron las credenciales de usuario en el localStorage."
      );
      return;
    }

    fetch(`${API_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: userEmail,
        password: userPassword,
        terms: "1",
        offers: "1",
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al iniciar sesión.");
        }
        return response.json();
      })
      .then((loginData) => {
        setUserProfile({
          name: loginData.user.name,
          level: loginData.user.level,
          avatar: loginData.user.photo
            ? `${BASE_URL}/${loginData.user.photo}`
            : GenericUserPhoto,
          badgeIcon: loginData.user.level_icon
            ? `${BASE_URL}/${loginData.user.level_icon}`
            : NoCataAvailable,
        });
        console.log("Nombre del usuario:", loginData.user.name);
        console.log("Nombre del usuario:", loginData.user.name);
      })
      .catch((error) => console.error("Error al iniciar sesión:", error));
  };

  useEffect(() => {
    loadProfile();
  }, []);

  useEffect(() => {
    if (selectedImage !== null) {
      handleImageUpload();
    }
  }, [selectedImage]);

  const handleCodigoWineChange = (event) => {
    setCodigoWine(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Código wine ingresado:", codigoWine);
    localStorage.setItem("codigo_wine", codigoWine); // Guardar el código wine en el localStorage
    handleClose();
    window.location.replace("/evaluacion-principal"); // Redirigir a evaluacion_pricinpal
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleImageUpload = () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      console.error(
        "No se encontró el token de autenticación en el localStorage"
      );
      return;
    }

    const formData = new FormData();
    formData.append("imagen", selectedImage);

    console.log(selectedImage);

    fetch(`${API_URL}/change-profilephoto`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al cambiar la foto de perfil.");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Foto de perfil cambiada exitosamente:", data);
        loadProfile();
        // Actualizar la foto de perfil en el estado local o volver a cargar el perfil
      })
      .catch((error) =>
        console.error("Error al cambiar la foto de perfil:", error)
      );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
      }}
    >
      <div style={styles.container}>
        <div style={styles.userAvatar}>
          <img style={styles.avatarImg} src={userProfile.avatar} alt="Avatar" />
          <div style={styles.badgeTop}>
            <img
              style={styles.badgeIcon}
              src={userProfile.badgeIcon}
              alt="Avatar"
            />
          </div>
          <div style={styles.badge}>
            <button
              style={styles.circularBtn}
              onClick={() => document.getElementById("fileInput").click()}
            >
              <img
                src={plusIcon}
                style={{
                  backgroundColor: "white",
                  borderRadius: "50%",
                  border: "none",
                  margin: "0",
                  padding: "0",
                }}
                alt="plusIcon"
              />
            </button>
          </div>
        </div>
        <div style={styles.userInfo}>
          <div style={styles.userName}>{userProfile.name}</div>
          <div style={styles.userLevel}>Nivel: {userProfile.level}</div>
        </div>
        <button style={styles.ctaButton} onClick={handleOpen}>
          <div style={styles.ctaText}>Ingresar código</div>
        </button>
      </div>
      <Modal open={open} handleClose={handleClose} description={description} />

      <SliderPromotion />
      <KnowWines />

      <input
        id="fileInput"
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleImageChange}
      />
    </div>
  );
}
