import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import show_icon from "assets/images/show_icon.svg";
import hide_icon from "assets/images/hide_icon.svg";
import { API_URL } from 'constants';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { Height } from '@mui/icons-material';

const ChangePassword = () => {
    const { correo } = useParams(); // Obtener el token de la URL
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [btnHover, setBtnHover] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const navigate = useNavigate();

    const handleMouseEnter = () => {
        setBtnHover(true);
      };
    
      const handleMouseLeave = () => {
        setBtnHover(false);
      };

      const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
    
        setOpenSnackbar(false);
      };

    const togglePasswordVisibility = (field) => {
        if (field === 'password') {
            setShowPassword(!showPassword);
        } else if (field === 'confirm_password') {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(`${API_URL}/email/resend`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ correo }),
            });
            const data = await response.json();
            console.log(data);

            if (response.ok) {
                setSnackbarMessage(data.message);
                setSnackbarSeverity("success");
                setOpenSnackbar(true); // Mostrar el Snackbar
        
            } else {
                setSnackbarMessage(data.message);
                setSnackbarSeverity("error");
                setOpenSnackbar(true); // Mostrar el Snackbar
                
            }
        } catch (error) {
            console.error('Error:', error);
            setSnackbarMessage('Error: Inténtalo de nuevo más tarde');
            setSnackbarSeverity("error");
            setOpenSnackbar(true); // Mostrar el Snackbar
        }
    };

    const handleChange = (event, field) => {
        if (field === 'email') {
            setEmail(event.target.value);
        } else if (field === 'password') {
            setPassword(event.target.value);
        } else if (field === 'confirm_password') {
            setConfirmPassword(event.target.value);
        }
    };

    const styles = {
        body: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontFamily: 'Avenir',
            marginTop: '20%'
        },
        contentContainer: {
            width: '70%',
            textAlign: 'center',
            fontFamily: 'Athelas',
            color: '#602131',
            marginBottom: '60px'
    
        },
        title: {
            fontSize: '32px',
            width: '100%',
            fontStyle: 'italic',
            lineHeight: '28.40px',
            textAlign: 'center',
        },
        description: {
            marginTop:'15px',
            width: '100%',
            height: '26px',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '16.80px',
            fontFamily: 'Avenir',
            textAlign: 'center',
        },
        descriptionsmall: {
            marginTop:'15px',
            width: '100%',
            height: 0,
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '12.80px',
            fontFamily: 'Avenir',
            textAlign: 'center',
        },
        container: {
            width: '65%',
            minHeight: '20%'
        },
        btn: {
            padding: '14px 30px',
            borderRadius: 50,
            border: '1px #602131 solid',
            color: '#602131',
            fontFamily:'Athelas',
            fontWeight:'bold',
            fontSize: '16px',
            textAlign: 'center',
            cursor: 'pointer',
            display: 'block',
            width: '100%',
            margin: '20px auto',
            marginTop: '50px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        input: {
            width: '100%',
            padding: '10px 0',
            marginTop:'25px',
            marginBottom: 15,
            border: 'none',
            borderBottom: '1px solid #C1A1A7',
            fontSize: 14,
            color: '#C1A1A7',
            background: 'transparent',
            outline: 'none',
            transition: 'all 0.3s',
            appearance: 'none',
        },
        snackbar: {
            position: 'fixed',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#ffffffff',
            color: '#602131',
            padding: '15px',
            borderRadius: '5px',
            zIndex: '999',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        passwordVisibilityButton: {
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            zIndex: 1,
            backgroundColor: 'transparent',
            border: 'none',
            outline: 'none',
            marginRight: '10px',
        },
        btnHover: {
            backgroundColor: "#502021",
            color: "white",
          },
    };

    return (
        <div style={styles.body}>
            <section style={styles.contentContainer}>
                <h1 style={styles.title}>Confirma tu cuenta</h1>
                <p style={styles.description}>
                Te enviamos un correo para que confirmes tu registro, por favor revisa spam en caso de que no lo veas.
                </p>
            </section>
            <div style={styles.container}>
                    <p style={styles.descriptionsmall}>
                    En caso de que no lo recibas, da click en el botón de abajo para enviarte un nuevo correo de verificación
                    </p>
                    <form onSubmit={handleSubmit}>
                        <input onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} type="submit" value="Reenviar correo" style={ btnHover ? { ...styles.btn, ...styles.btnHover, color: "white" } : styles.btn } />
                    </form>
                    <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ height: "100%", whiteSpace: "pre-wrap" }}
        >
          {snackbarMessage} 
        </Alert>
      </Snackbar>
            </div>
        </div>
    );
};

export default ChangePassword;
