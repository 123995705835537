import React, { useState, useEffect } from "react";
import SingleNavbar from "components/App/SingleNavbar";
import CentroAyuda from "components/App/CentroAyuda";
import Footer from "components/App/Footer";
import BackButton from "components/App/BackButton";
import NavbarWithStart from "components/App/NavbarWithStart";

function LayoutTerm() {


  const styles = {
    body: {
      display:'flex',
      flexDirection:'column',
      minHeight: "100vh",
      margin: 0,
      padding: 0,
      background:
        "#F7F3EF" /* Sets the background color of the app to a light beige */,
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      margin: "65px",
      gap: "16px",
    },
    BackButton: {
      alignItems: "left",
      position: "absolute",
    },
  };
  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <NavbarWithStart/>
      </div>

      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor:'#F7F3EF',
          overflowY: "scroll"
        }}
      >
        <CentroAyuda />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor:'#F7F3EF'
        }}
      >
        <Footer />
      </div>
    </div>
  );
}

export default LayoutTerm;
