import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Modal from 'components/App/Modal'; // Importamos el componente de Modal desde su archivo separado
import { API_URL } from 'constants';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CustomMenu from './Menu';

export default function NavBarButton() {
  const [open, setOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [btnHover, setBtnHover] = React.useState(false);

  React.useEffect(() => {
    // Llamada al endpoint para obtener la descripción
    fetch(`${API_URL}/view-homenotification`)
    .then(response => response.json())
    .then(data => {
      setDescription(data?.home_notification?.description || "");
    })
    .catch(error => {
      console.error('Error fetching description:', error);
      // En caso de error, se mantiene la descripción vacía
    });
  }, []); // Se ejecuta solo una vez al montar el componente

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleMouseEnter = () => {
    setBtnHover(true);
  };

  const handleMouseLeave = () => {
    setBtnHover(false);
  };

  const handleSaveToHomeScreen = () => {
    // Verificar si la API de instalación está disponible
    if ('navigator' in window && 'standalone' in window.navigator) {
      if (window.navigator.standalone) {
        // La aplicación ya está instalada en la pantalla de inicio
        alert('La aplicación ya está instalada en la pantalla de inicio.');
      } else {
        // Se puede agregar un acceso directo a la pantalla de inicio
        alert('Por favor, toca el botón "Compartir" o "Agregar a la pantalla de inicio" para guardar.');
      }
    } else {
      // La API de instalación no está disponible
      alert('La API de instalación no está disponible en este navegador.');
    }
  };

  const styles = {
    container: {
      flexGrow: 1
    },
    appBar: {
      position: 'absolute',
      width: '100%',
      backgroundColor: 'transparent',
    },
    toolbar: {
      minHeight: '100px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      paddingLeft: '30px',
      paddingRight: '30px',
      paddingTop: '14px',
      paddingBottom: '14px',
      backgroundColor: '#602131',
      borderRadius: '35px',
      border: 'none',
      cursor: 'pointer',
      textAlign: 'center',
      color: '#F7F3EF',
      fontSize: '13px',
      fontFamily: 'Athelas',
      fontWeight: 700,
      lineHeight: '15.60px',
      wordWrap: 'break-word',
    },
    btnHover: {
      border: '1px #602131 solid',
      backgroundColor: '#F7F3EF',
      color: "#602131",
    },
    buttonText: {
      textAlign: 'center',
      color: '#F7F3EF',
      fontSize: '13px',
      fontFamily: 'Athelas',
      fontWeight: 700,
      lineHeight: '15.60px',
      wordWrap: 'break-word',
    },
    modalBox: {
      position: 'absolute',
      width: '80%',
      maxWidth: '400px',
      backgroundColor: 'white',
      padding: '16px',
      outline: 'none',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '8px',
      color: '#602131',
      textAlign: 'center',
      fontFamily: 'Athelas',
    },
    modalTitle: {
      color: '#602131',
      fontSize: '18px',
    },
    btn: {
      padding: '14px 30px',
      borderRadius: 50,
      border: '1px #602131 solid',
      color: '#602131',
      fontWeight: 'bold',
      textAlign: 'center',
      cursor: 'pointer',
      display: 'block',
      width: '80%',
      margin: '20px auto',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  return (
    <Box sx={styles.container}>
      <AppBar position="absolute" elevation={0} sx={styles.appBar}>
        <Toolbar sx={styles.toolbar}>

          <IconButton size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleOpenMenu}>
            <MenuIcon sx={{ fontSize: '2.5rem' }}/>
          </IconButton>

          <IconButton size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, flexGrow:1 }}>
          </IconButton>

          <button onClick={handleOpen} style={
              btnHover
                ? { ...styles.button, ...styles.btnHover }
                : styles.button
            } onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            Comenzar
          </button>
        </Toolbar>
      </AppBar>

      <Modal open={open} handleClose={handleClose} description={description} />
      <CustomMenu open={openMenu} handleClose={handleCloseMenu}/>

    </Box>
  );
}
