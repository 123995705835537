import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from "@mui/material/Alert";
import show_icon from "assets/images/show_icon.svg";
import hide_icon from "assets/images/hide_icon.svg";
import { createRoot } from 'react-dom/client';
import { API_URL } from 'constants';
import moment from "moment";
import userEvent from '@testing-library/user-event';

const ChangePassword = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [estado, setEstado] = useState('Mexico');
    const [cumpleanos, setCumpleanos] = useState('');
    const [phone, setPhone] = useState('');
    const [state, setState] = useState('');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showPassword, setShowPassword] = useState(true);
    const [showConfirmPassword, setShowConfirmPassword] = useState(true);
    const [formFields, setFormFields] = useState({});
    const [inputType, setInputType] = useState("text");
    const [isOfAge, setIsOfAge] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [passwordsMatch, setPasswordsMatch] = useState(true); // Nuevo estado
    const [btnHover, setBtnHover] = useState(false);

  function NewlineText(props) {
    const text = props.text;
    const newText = text.split(";").map((str) => <p>{str}</p>);

    return newText;
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const toggleInput = (event) => {
    if (inputType === "text") setInputType("date");
    setTimeout(() => {
      event.target.showPicker();
    }, 300);
  };

  const toggleInputBlur = () => {
    if (inputType === "date") setInputType("text");
  };

    useEffect(() => {
        // Recuperar datos del localStorage
        const userJson = localStorage.getItem('userData');
        // Verificar si los datos existen
        if (userJson) {
            const userData = JSON.parse(userJson);
            setFormData(userData);
        }
        const fetchData = async () => {
            try {
              const response = await fetch(`${API_URL}/view-register`);
              const data = await response.json();
              setFormFields(data.formulario);
            } catch (error) {
              console.error("Error fetching form fields:", error);
            }
          };
      
          fetchData();
    }, []);

    // Estados para las entradas
  const [formData, setFormData] = useState({
    name: "",
    lastname: "",
    email: "",
    state: "",
    birthdate: "",
    gender: "",
    phone: "",
    password: "",
    password_confirmation: "",
    extrafield1: "",
    extrafield2: "",
    extrafield3: "",
    extrafield4: "",
    extrafield5: "",
  });

    const togglePasswordVisibility = (field) => {
        if (field === 'password') {
            setShowPassword(!showPassword);
        } else if (field === 'confirm_password') {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!isOfAge) {
            setSnackbarMessage("Debes ser mayor de 18 años para registrarte.");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            return;
          }
        const token = localStorage.getItem('authToken');
        const updateData = {
            name: nombre,
            lastname: apellido,
            state: estado,
            birthdate: cumpleanos,
            phone: phone,
            password: password,
            password_confirmation: confirmPassword
        };
        try {
            const response = await fetch(`${API_URL}/update-profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}` // Aquí se incluye el token en el encabezado Authorization
                },
                body: JSON.stringify(formData)
            });
            const data = await response.json();
            if (response.ok && data.message && data.message.includes('Success')) {
              const userData = JSON.parse(localStorage.getItem('userData'));
              Object.keys(formData).forEach((key) => {
                userData[key] = formData[key];
              });
              localStorage.setItem('userData', JSON.stringify(userData));
                setSnackbarMessage('Se actualizó exitosamente el perfil');
                setSnackbarSeverity("success");
                setOpenSnackbar(true);
                
            } else {
                setSnackbarMessage(data.message);
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error('Error al actualizar el perfil:', error);
            setSnackbarMessage('Error al actualizar el perfil, por favor inténtelo de nuevo más tarde');
            setOpenSnackbar(true);
        }
    };

    const handleChange = (e) => {
        const value =
          e.target.type === "checkbox" ? e.target.checked : e.target.value;
        const newFormData = {
          ...formData,
          [e.target.id]: value,
        };
        setFormData(newFormData);
        if (e.target.id === "birthdate") {
          setIsOfAge(validateAge(e.target.value));
        }
    
        // Validar si las contraseñas coinciden cada vez que el usuario modifica las entradas
        if (e.target.id === "password" || e.target.id === "password_confirmation") {
          if (newFormData.password !== newFormData.password_confirmation) {
            setPasswordsMatch(false); // Las contraseñas no coinciden
          } else {
            setPasswordsMatch(true); // Las contraseñas coinciden
          }
        }
    };

    const validateAge = (dob) => {
        const birthDate = new Date(dob);
        const currentDate = new Date();
        const age =
          currentDate.getFullYear() -
          birthDate.getFullYear() -
          (currentDate.getMonth() < birthDate.getMonth() ||
          (currentDate.getMonth() === birthDate.getMonth() &&
            currentDate.getDate() < birthDate.getDate())
            ? 1
            : 0);
        return age >= 18;
      };

      const handleMouseEnter = () => {
        setBtnHover(true);
      };
    
      const handleMouseLeave = () => {
        setBtnHover(false);
      };

    const styles = {
        body: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontFamily: 'Avenir',
            paddingBottom: '50px' // Agregamos margen inferior al cuerpo para separarlo del borde inferior de la ventana
        },
        contentContainer: {
            width: '100%',
            textAlign: 'center',
            fontFamily: 'Athelas',
            color: '#602131',
            marginTop: '50px',    
        },
        title: {
            fontSize: '32px',
            width: '110%',
            fontStyle: 'italic',
            lineHeight: '38.40px',
            textAlign: 'center',
        },
        container: {
            width: '100%',
            position: 'relative'
        },
        btn: {
            padding: '14px 30px',
            borderRadius: 50,
            border: '1px #602131 solid',
            color: '#602131',
            fontWeight: 'bold',
            textAlign: 'center',
            cursor: 'pointer',
            display: 'block',
            width: '80%',
            margin: '20px auto',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        input: {
            width: '100%',
            padding: '10px 0',
            marginBottom: 15,
            border: 'none',
            borderBottom: '1px solid #C1A1A7',
            fontSize: 14,
            color: '#C1A1A7',
            background: 'transparent',
            outline: 'none',
            transition: 'all 0.3s',
            //appearance: 'none',
            '::placeholder': {
                color: '#602131',
            },
        },
        passwordVisibilityButton: {
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            zIndex: 1,
            backgroundColor: 'transparent',
            border: 'none',
            outline: 'none',
            marginRight: 0,
        },
        btnHover: {
          backgroundColor: "#502021",
          color: "white",
        },
    };

    return (
        <div style={styles.body}>
            <section style={styles.contentContainer}>
                <h1 style={styles.title}>Tus datos</h1>
            </section>
            <div style={styles.container}>
                <form onSubmit={handleSubmit}>
                {formFields &&
            Object.values(formFields).map((campo, index) => {

                if (campo.tag == "name" ) {
                    return (
                      <input
                        key={index}
                        type="text"
                        id={campo.tag}
                        value={formData['name']}
                        onChange={handleChange}
                        required
                        placeholder={
                          (campo.placeholder ? campo.placeholder : "") + "*"
                        }
                        style={styles.input}
                      />
                    );
                }

                if (campo.tag == "lastname" ) {
                    return (
                      <input
                        key={index}
                        type="text"
                        id={campo.tag}
                        value={formData['lastname']}
                        onChange={handleChange}
                        required
                        placeholder={
                          (campo.placeholder ? campo.placeholder : "") + "*"
                        }
                        style={styles.input}
                      />
                    );
                }

                if (campo.tag == "email" ) {
                    return (
                      <input
                        key={index}
                        type="text"
                        id={campo.tag}
                        value={formData['email']}
                        onChange={handleChange}
                        required
                        placeholder={
                          (campo.placeholder ? campo.placeholder : "") + "*"
                        }
                        style={styles.input}
                      />
                    );
                }
                
                if (campo.tag == "state") {
                    return (
                      <select
                        id="state"
                        onChange={handleChange}
                        required
                        style={styles.input}
                        value={formData['state']} 
                        defaultValue={'DEFAULT'}
                      >
                        <option value="" disabled selected>
                          {campo.placeholder ? campo.placeholder : ""}*
                        </option>
                        <option value="Aguascalientes">Aguascalientes</option>
                        <option value="Baja California">Baja California</option>
                        <option value="Baja California Sur">
                          Baja California Sur
                        </option>
                        <option value="Campeche">Campeche</option>
                        <option value="Chiapas">Chiapas</option>
                        <option value="Chihuahua">Chihuahua</option>
                        <option value="Ciudad de México">Ciudad de México</option>
                        <option value="Coahuila de Zaragoza">
                          Coahuila de Zaragoza
                        </option>
                        <option value="Colima">Colima</option>
                        <option value="Durango">Durango</option>
                        <option value="Estado de México">Estado de México</option>
                        <option value="Guanajuato">Guanajuato</option>
                        <option value="Guerrero">Guerrero</option>
                        <option value="Hidalgo">Hidalgo</option>
                        <option value="Jalisco">Jalisco</option>
                        <option value="Michoacán">Michoacán</option>
                        <option value="Morelos">Morelos</option>
                        <option value="Nayarit">Nayarit</option>
                        <option value="Nuevo León">Nuevo León</option>
                        <option value="Oaxaca">Oaxaca</option>
                        <option value="Puebla">Puebla</option>
                        <option value="Querétaro">Querétaro</option>
                        <option value="Quintana Roo">Quintana Roo</option>
                        <option value="San Luis Potosí">San Luis Potosí</option>
                        <option value="Sinaloa">Sinaloa</option>
                        <option value="Sonora">Sonora</option>
                        <option value="Tabasco">Tabasco</option>
                        <option value="Tamaulipas">Tamaulipas</option>
                        <option value="Tlaxcala">Tlaxcala</option>
                        <option value="Veracruz">Veracruz</option>
                        <option value="Yucatán">Yucatán</option>
                        <option value="Zacatecas">Zacatecas</option>
                      </select>
                    );
                }
    
                if (campo.tag == "birthdate") {
                    return (
                      <div style={{ display: "flex", flexDirection: "row"}}>
                        <style>
                          {`input[type="date"]::-webkit-inner-spin-button,
                            input[type="date"]::-webkit-calendar-picker-indicator {
                            display: none;
                            -webkit-appearance: none;}
                            `}
                        </style>
                        <input
                          type={inputType}
                          id="birthdate"
                          value={formData['birthdate']}
                          placeholder={
                            (campo.placeholder ? campo.placeholder : "") + "*"
                          }
                          onClick={toggleInput}
                          onBlur={toggleInputBlur}
                          onKeyDown={(event) => false}
                          maxDate={moment().subtract(18, "years")._d}
                          style={{...styles.input}}
                          onChange={handleChange}
                        />
                        {!isOfAge && (
                          <span style={{ color: "red", fontSize: 12 }}>
                            Debes ser mayor de 18 años para registrarte.
                          </span>
                        )}
                        <label
                          style={{
                            position: "absolute",
                            right: "3px",
                            alignItems: "center",
                            marginTop: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <svg
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 4V1M18 4V1M3 8.5H21M4 4.5H20C20.8284 4.5 21.5 5.17157 21.5 6V19C21.5 19.8284 20.8284 20.5 20 20.5H4C3.17157 20.5 2.5 19.8284 2.5 19V6C2.5 5.17157 3.17157 4.5 4 4.5Z"
                  stroke="#602131"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
                        </label>
                      </div>
                    );
                }
    
                if (campo.tag == "gender") {
                    return (
                      <select
                        id="gender"
                        onChange={handleChange}
                        required
                        style={styles.input}
                        defaultValue={formData['gender']}
                      >
                        <option value="" disabled selected>
                          {campo.placeholder ? campo.placeholder : ""}*
                        </option>
                        <option value="masculino">Masculino</option>
                        <option value="femenino">Femenino</option>
                        <option value="otro">Otro</option>
                      </select>
                    );
                }
    
                if (campo.tag == "phone") {
                    return (
                      <input
                        type="text"
                        id="phone"
                        value={formData['phone']}
                        onChange={handleChange}
                        required
                        placeholder={campo.placeholder ? campo.placeholder : "" + "*"} 
                        style={styles.input}
                      />
                    );
                }
    
                if (campo.tag == "extrafield1") {
                    return (
                      <input
                        key={index}
                        type="text"
                        id={campo.tag}
                        value={formData['extrafield1']}
                        onChange={handleChange}
                        required
                        placeholder={campo.placeholder ? campo.placeholder : "" + "*"} 
                        style={styles.input}
                      />
                    );
                }
    
                if (campo.tag == "extrafield2") {
                    return (
                      <input
                        key={index}
                        type="text"
                        id={campo.tag}
                        value={formData['extrafield2']}
                        onChange={handleChange}
                        required
                        placeholder={campo.placeholder ? campo.placeholder : "" + "*"} 
                        style={styles.input}
                      />
                    );
                }
    
                if (campo.tag == "extrafield3") {
                    return (
                      <input
                        key={index}
                        type="text"
                        id={campo.tag}
                        value={formData['extrafield3']}
                        onChange={handleChange}
                        required
                        placeholder={campo.placeholder ? campo.placeholder : "" + "*"}
                        style={styles.input}
                      />
                    );
                }
    
                if (campo.tag == "extrafield4") {
                    return (
                      <input
                        key={index}
                        type="text"
                        id={campo.tag}
                        value={formData['extrafield4']}
                        onChange={handleChange}
                        required
                        placeholder={campo.placeholder ? campo.placeholder : "" + "*"}
                        style={styles.input}
                      />
                    );
                }
    
                if (campo.tag == "extrafield5") {
                    return (
                      <input
                        key={index}
                        type="text"
                        id={campo.tag}
                        value={formData['extrafield5']}
                        onChange={handleChange}
                        required
                        placeholder={campo.placeholder ? campo.placeholder : "" + "*"}
                        style={styles.input}
                      />
                    );
                }
                })}
                   
                {/* Campo de contraseña */}
          <div style={{ position: "relative" }}>
            <input
              type={showPassword ? "text" : "password"} // Cambiar el tipo de acuerdo al estado de visibilidad
              id="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Contraseña*"
            
              style={styles.input}
            />
            {/* Botón de visibilidad de la contraseña */}
            <button
              type="button"
              onClick={() => togglePasswordVisibility("password")}
              style={styles.passwordVisibilityButton}
            >
              <img
                src={showPassword ? show_icon : hide_icon}
                alt={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                style={{ width: "15px" }}
              />
            </button>
          </div>

          {/* Campo de confirmación de contraseña */}
          <div style={{ position: "relative" }}>
            <input
              type={showConfirmPassword ? "text" : "password"} // Cambiar el tipo de acuerdo al estado de visibilidad
              id="password_confirmation"
              value={formData.password_confirmation}
              onChange={handleChange}
              placeholder={
                (formFields.input_confirm_pass
                  ? formFields.input_confirm_pass.placeholder
                  : "") + "*"
              }
              style={
                passwordsMatch
                  ? styles.input
                  : { ...styles.input, borderColor: "red" }
              }
            />
            {/* Botón de visibilidad de la contraseña de confirmación */}
            <button
              type="button"
              onClick={() => togglePasswordVisibility("confirm_password")}
              style={styles.passwordVisibilityButton}
            >
              <img
                src={showConfirmPassword ? show_icon : hide_icon}
                alt={
                  showConfirmPassword
                    ? "Ocultar contraseña"
                    : "Mostrar contraseña"
                }
                style={{ width: "15px" }}
              />
            </button>
          </div>
          {!passwordsMatch && (
            <span style={{ color: "red", fontSize: 12 }}>
              Las contraseñas no coinciden
            </span>
          )}
        <input type="submit" value="Guardar"  style={
              btnHover
                ? { ...styles.btn, ...styles.btnHover, color: "white" }
                : styles.btn
            }
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
             />
    </form>
</div>
<Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ height: "100%", whiteSpace: "pre-wrap" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
        </div>
    );
};

export default ChangePassword;
