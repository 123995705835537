import React from "react";
import EvaluacionPrincipal from "components/App/EvaluacionVisual";
import Footer from "components/App/Footer";
import WineEvaluationComponent from "components/App/WineEvaluationComponent";
import useTastingQuestions from "hooks/useTastingQuestions";

function LayoutTerm() {
  const [tastingData, imageCover, messageEror] = useTastingQuestions(
    "view-tastingquestions1",
    "Comencemos",
    "error"
  );

  const styles = {
    container: {
      backgroundColor: "#ffff",
    },
  };
  return (
    <div style={styles.container}>
      <WineEvaluationComponent imageCover={imageCover} messageEror={messageEror} />
      <EvaluacionPrincipal tastingData={tastingData} />
      <Footer />
    </div>
  );
}

export default LayoutTerm;
