import React, { useState, useEffect } from 'react';
import { API_URL } from 'constants';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const ChangePassword = () => {
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('Ingresa tu correo con el que te registraste para poder hacer la actualización de contraseña.');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [btnHover, setBtnHover] = React.useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleMouseEnter = () => {
        setBtnHover(true);
      };
    
      const handleMouseLeave = () => {
        setBtnHover(false);
      };

      const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
    
        setOpenSnackbar(false);
      };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(`${API_URL}/forgot-password`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            const data = await response.json();
            console.log(data); // Puedes manejar la respuesta de la API aquí

            if (response.ok) {
                setDescription(data.status);
                setIsVisible(false);
            }else{

                setSnackbarMessage(data.msg);
                setSnackbarSeverity("error");
                setOpenSnackbar(true); // Mostrar el Snackbar
            } 
        } catch (error) {
            console.error('Error:', error);
            setDescription('Error: Inténtalo de nuevo más tarde'); // Establecer mensaje de error del Snackbar
        }
    };

    

    const handleChange = (event) => {
        setEmail(event.target.value);
    };

    const styles = {
        body: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent:'center',
        },
        contentContainer: {
            width: '90%',
            textAlign: 'center',
            fontFamily: 'Athelas',
            color: '#602131',

        },
        title: {
            fontSize: '32px',
            width: '100%',
            fontStyle: 'italic',
            lineHeight: '38.40px',
            textAlign: 'center',
        },
        description: {
            marginTop: '15px',
            width: '100%',
            height: '36px',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '16.80px',
            fontFamily: 'Avenir',
            textAlign: 'center',
        },
        container: {
            width: '90%',
            marginBottom: '0px'
        },
        btn: {
            padding: '14px 30px',
            borderRadius: 50,
            border: '1px #602131 solid',
            color: '#602131',
            fontFamily:'Athelas',
            fontWeight:'bold',
            fontSize: '18px',
            textAlign: 'center',
            cursor: 'pointer',
            display: 'block',
            width: '80%',
            margin: '20px auto',
            marginTop: '50px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        input: {
            width: '100%',
            padding: '10px 0',
            marginTop: '25px',
            marginBottom: 15,
            border: 'none',
            borderBottom: '1px solid #C1A1A7',
            fontSize: 14,
            color: '#C1A1A7',
            background: 'transparent',
            outline: 'none',
            transition: 'all 0.3s',
            appearance: 'none',
        },
        snackbar: {
            position: 'relative',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#ffffffff',
            color: '#602131',
            padding: '15px',
            borderRadius: '5px',
            zIndex: '999',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        btnHover: {
            backgroundColor: "#502021",
            color: "white",
          },
    };

    return (
        <div style={styles.body}>
            <section style={styles.contentContainer}>
                <h1 style={styles.title}>Cambio de contraseña</h1>
                <p style={styles.description}>
                    {description}
                </p>
            </section>
            {isVisible && (
                <div style={styles.container}>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="email"
                            id="correo"
                            required
                            placeholder="Correo*"
                            style={styles.input}
                            value={email}
                            onChange={handleChange}
                        />
                        <input onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} type="submit" value="Enviar correo" style={ btnHover ? { ...styles.btn, ...styles.btnHover, color: "white" } : styles.btn } />
                    </form>
                    <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ height: "100%", whiteSpace: "pre-wrap" }}
        >
          {snackbarMessage} 
        </Alert>
      </Snackbar>
                </div>
            )} 
        </div>
    );
};

export default ChangePassword;
