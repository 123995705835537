import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';

export default function CustomModal({ open, handleClose }) {
  const isLoggedIn = !!localStorage.getItem('authToken'); // Determina si el usuario está logeado
  const [codigoWine, setCodigoWine] = React.useState(""); // Estado para el código del vino
  const [showLoginButton, setShowLoginButton] = React.useState(false); // Estado para mostrar el botón de iniciar sesión
  const [btnHover, setBtnHover] = React.useState(false);
  const [btnHoverLogin, setBtnHoverLogin] = React.useState(false);

  const handleMouseEnter = () => {
    setBtnHover(true);
  };

  const handleMouseLeave = () => {
    setBtnHover(false);
  };

  const handleMouseEnterLogin = () => {
    setBtnHoverLogin(true);
  };

  const handleMouseLeaveLogin = () => {
    setBtnHoverLogin(false);
  };

  const handleCodigoWineChange = (event) => {
    setCodigoWine(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Código wine ingresado:", codigoWine);
    localStorage.setItem('codigo_wine', codigoWine); // Guardar el código wine en el localStorage
    handleClose();
    window.location.replace('/evaluacion-principal'); // Redirigir a evaluacion_principal
  };

  React.useEffect(() => {
    if (!isLoggedIn) {
      setShowLoginButton(true); // Mostrar el botón de iniciar sesión si no está logeado
    }
  }, [isLoggedIn]);

  const handleLogin = () => {
    // Lógica para redirigir a la página de inicio de sesión
    window.location.replace('/login'); // Redirigir a evaluacion_principal
  };

  const styles = {
    modalBox: {
      position: 'absolute',
      width: '80%',
      maxWidth: '400px',
      backgroundColor: 'white',
      padding: '16px',
      outline: 'none',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '25px',
      color: '#602131',
      textAlign: 'center',
      fontFamily: 'Athelas',
      position: 'relative', // Agregado para el posicionamiento del botón de cierre
    },
    closeButton: {
      position: 'absolute',
      top: '-5px',
      right: '15px',
      cursor: 'pointer',
      color: '#000000',
      fontSize: '42px',
    },
    modalTitle: {
      top: '40px',
      color: '#602131',
      fontSize: '18px',
    },
    btn: {
      padding: "14px 30px",
      borderRadius: 50,
      border: "1px #602131 solid",
      color: "#602131",
      fontWeight: "bold",
      textAlign: "center",
      cursor: "pointer",
      display: "block",
      width: "80%",
      margin: "20px auto",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#ffffffff",
    },
    btnHover: {
      backgroundColor: "#502021",
      color: "white",
    },
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Box sx={styles.modalBox}>
          <span style={styles.closeButton} onClick={handleClose}>×</span> {/* Botón de cierre */}
          {isLoggedIn ? (
            // Si está logeado, mostrar el contenido del formulario
            <div style={{ textAlign: "center" }}>
              <span style={{ textAlign: "center", fontSize: "32px", display: "block", marginBottom: "20px", marginTop: "50px", fontFamily:'Athelas', fontStyle:'italic', fontWeight:'bold' }}>Ingresa el código</span>
              <p style={{ textAlign: "center" }}>Busca el número alfanumérico en tu caja</p>
              <form onSubmit={handleSubmit}>
                <input
                  style={{
                    width: '100%',
                    padding: '10px 0',
                    marginBottom: 15,
                    border: 'none',
                    borderBottom: '1px solid #C1A1A7',
                    fontSize: 14,
                    color: '#C1A1A7',
                    background: 'transparent',
                    outline: 'none',
                    transition: 'all 0.3s',
                    appearance: 'none',
                  }}
                  type="text"
                  id="codigo_wine"
                  name="codigo_wine"
                  value={codigoWine}
                  onChange={handleCodigoWineChange}
                  placeholder="Código*"
                  required
                />
                <button
                  style={{
                    padding: '14px 30px',
                    borderRadius: 50,
                    border: '1px solid #602131',
                    color: '#602131',
                    fontFamily:'Athelas',
                    fontSize:'18px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    cursor: 'pointer',
                    display: 'block',
                    width: '80%',
                    margin: '20px auto',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#ffffff',
                  }}
                  type="submit"
                >
                  Ingresar
                </button>
              </form>
            </div>
          ) : (
            // Si no está logeado, mostrar el mensaje de iniciar sesión
            <div>
              <Typography variant="h6" id="modal-title" style={{ textAlign: "center", fontSize: "33px", display: "block", marginBottom: "20px", marginTop: "50px" }}>
                ¿Aún no eres miembro?
              </Typography>
              <Typography variant="subtitle1" id="modal-description">
                Inicia sesión o regístrate para comenzar con la experiencia.
              </Typography>
              <div>
              <a href="/registro" onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave} style={
              btnHover
                ? { ...styles.btn, ...styles.btnHover, color: "white" }
                : styles.btn
            }>Regístrate</a></div>
              {showLoginButton && (
                <div onClick={handleLogin}>
                  <a href="/login" onMouseEnter={handleMouseEnterLogin}
            onMouseLeave={handleMouseLeaveLogin} style={
              btnHoverLogin
                ? { ...styles.btn, ...styles.btnHover, color: "white" }
                : styles.btn
            }>Iniciar sesión</a></div>
              )}
            </div>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}
