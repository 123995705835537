import * as React from "react";
import { Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import LogoBlk from "assets/images/Logo-negro.svg";
import Modal from 'components/App/Modal'; 
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CustomMenu from "./Menu";

export default function NavbarWithStart() {

  const [open, setOpen] = React.useState(false); // Estado para controlar el modal
  const [openMenu, setOpenMenu] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const styles = {
    tabContainer: {
      width: "100%",
      marginTop: "80px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 20,
    },
    tabLink: {
      flex: 1,
      textAlign: "center",
      color: "#602131",
      fontSize: 21,
      fontFamily: "Athelas",
      fontStyle: "italic",
      fontWeight: 700,
      textDecoration: "none",
      padding: "10px 0",
      borderBottom: "3px solid #602131",
    },
    activeTabLink: {
      color: "#C1A1A7",
      borderBottom: "1px solid #C1A1A7",
    },
    container: {
      width: "100%",
      margin: "auto",
    },
    socialButtons: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 20,
    },
    btn: {
      paddingLeft: "30px",
      paddingRight: "30px",
      paddingTop: "14px",
      paddingBottom: "14px",
      background: "#602131",
      borderRadius: "35px",
      border: "none",
      cursor: "pointer",
    },
    modalTitle: {
      color: "#602131",
      fontsize: "18px",
    },
    buttonText: {
      textAlign: "center",
      color: "#F7F3EF",
      fontSize: "13px",
      fontFamily: "Athelas",
      fontWeight: 700,
      lineHeight: "15.60px",
      wordWrap: "break-word",
    },
  };
  return (
    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
      <AppBar
        position="relative"
        elevation={0}
        sx={{ width: "100%", backgroundColor: "#F7F3EF" }}
      >
        <Toolbar
          sx={{
            minHeight: "100px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, color: '#602131' }}
            onClick={handleOpenMenu}>
            <MenuIcon sx={{ fontSize: '2.5rem' }}/>
          </IconButton>
          <Link to="/">
            <img src={LogoBlk} alt="Logo" style={{ cursor: "pointer" }} />
          </Link>
          <button style={styles.btn} onClick={handleOpen}>  <span style={styles.buttonText}>Comenzar</span></button>
        </Toolbar>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              borderBottom: "1px solid #C1A1A7",
              width: "90%",
            }}
          />
        </div>
      </AppBar>
      <Modal open={open} handleClose={handleClose} description="tEXTO" />
      <CustomMenu open={openMenu} handleClose={handleCloseMenu}/>
    </Box>
  );
}
