import React, { useState, useEffect } from 'react';
import { API_URL, BASE_URL } from 'constants';
import BackProfileButton from './BackProfileButton';

const styles = {
  container: {
    height:'90%',
    textAlign: 'center',
    position: 'relative',
    alignItems: 'center',
    paddingTop:'20px'
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  subtitle: {
    fontSize: '14px',
    marginBottom: '20px',
  },
  card: {
    width: '90%',
    height: '100px',
    borderRadius: '10px',
    margin: '20px auto',
    border: '1px solid #FFFFFF',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',

  },
  image: {
    width: '25%',
    height: '100px',
    objectFit: 'cover',
  },
  titleInCard: {
    marginLeft: '15px',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  status: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    borderRadius: '25px',
    border: '1px solid #602131',
    color: '#602131',
    padding: '5px',
    fontSize: '10px',

  },
  BackButton: {
    position: 'absolute',
    left: 0,
    top: 0,
    margin: '20px',
  },
};

const CardList = () => {
  const [cards, setCards] = useState([]);
  const [nocards, setNoCards] = useState([]);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await fetch(`${API_URL}/get-tasting`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${authToken}`,
            'User-Agent': 'insomnia/2023.5.8',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setCards(data.evaluations);
        setNoCards(data.no_evaluations);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCards();
  }, []);

  return (
    <div style={{height:'100%'}}>
      <div style={{marginTop:'10px', marginBottom:'10px'}}>
        <BackProfileButton/>
      </div>
      <div style={styles.container}>
      <h1 style={styles.title}>{nocards.title}</h1>
      <p style={styles.subtitle}>{nocards.subtitle}</p>
      {cards.map((card, index) => (
        <div key={index} style={styles.card}>
          <img src={`${BASE_URL}/${card.image}`} alt="Card" style={styles.image} />
          <div style={styles.titleInCard}>{card.title}</div>
          <div style={styles.status}>{card.status}</div>
        </div>
      ))}
    </div>
    </div>
    
  );
};

export default CardList;
