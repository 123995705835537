import React from "react";
// Así debes importarlo si estás usando export default
import UserProfile from "components/App/UserProfile";

import Footer from "components/App/Footer";
import NavbarWithStart from "components/App/NavbarWithStart";

function LayoutTerm() {

  return (
    <div
    style={{
      width: "100%",
      minHeight:'100vh',
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#FFFFFF",
    }}
  >
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavbarWithStart/>
    </div>

    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor:'#F7F3EF'
      }}
    >
      <UserProfile />
    </div>
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor:'#F7F3EF'
      }}
    >
      <Footer />
    </div>
  </div>
  );
}

export default LayoutTerm;

