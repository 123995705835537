import React from "react";
import EvaluacionPrincipal from "components/App/EvaluacionPrincipal";
import Footer from "components/App/Footer";
import MainWineEvaluationComponent from "components/App/MainWineEvaluationComponent";
import ImageCover from "assets/images/Group2659.jpg";

function LayoutTerm() {

  const styles = {
    container: {
      width: "100%", // Asegura que el contenedor ocupe todo el ancho disponible
    },
    image: {
      width: "100%",
      height: "auto",
      display: "block",
    },
  };

  return (
    <div style={styles.container}>
      <MainWineEvaluationComponent imageCover={ImageCover}/>
      <EvaluacionPrincipal />
      <Footer />
    </div>
  );
}

export default LayoutTerm;
