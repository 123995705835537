import React, { useState, useEffect, useRef } from 'react';
import { BASE_URL, API_URL } from 'constants';

const CarouselComponent = () => {
  const [promotionsData, setPromotionsData] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const carouselRef = useRef(null);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');

    if (!authToken) {
      console.error('No se encontró el token de autenticación en el localStorage');
      return;
    }

    // Aquí realizarías la llamada a la API para obtener los datos de las promociones
    fetch(`${API_URL}/view-userprofile`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => {
      // Reemplazar todas las ocurrencias de \/ con /
      const fixedData = fixUrls(data);
      setPromotionsData(fixedData);
    })
    .catch(error => console.error('Error fetching promotions data:', error));
  }, []);

  const handleTouchStart = (event) => {
    setTouchStart(event.touches[0].clientX);
  };

  const handleTouchMove = (event) => {
    setTouchEnd(event.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 100) {
      setCurrentSlide(prevSlide => Math.min(prevSlide + 1, promotionsData?.wines.slider_wines.length - 1 || 0));
    }

    if (touchStart - touchEnd < -100) {
      setCurrentSlide(prevSlide => Math.max(prevSlide - 1, 0));
    }
  };

  // Función para reemplazar \/ con /
  const fixUrls = (data) => {
    const fixedData = JSON.parse(JSON.stringify(data).replace(/\\\//g, '/'));
    return fixedData;
  };

  return (
    <div style={{ textAlign: 'center' }}>
      {promotionsData && (
        <div>
          {/* Título */}
          <h2 style={{ fontWeight: 'bold', fontSize: '33px', marginTop: '20px' }}>{promotionsData.promotions.title_promo}</h2>
          {/* Carrusel */}
          <div
            ref={carouselRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{
              width: '80%',
              margin: '0 auto',
              overflow: 'hidden',
              marginTop: '20px',
              position: 'relative',
            }}
          >
            <div
              style={{
                display: 'flex',
                transition: 'transform 0.5s ease',
                transform: `translateX(-${currentSlide * 100}%)`,
              }}
            >
              {promotionsData.promotions.slider_promo.map((slide, index) => (
                <div
                  key={index}
                  style={{
                    flex: '0 0 auto',
                    width: '100%',
                    marginRight: '0px',
                    borderRadius: '5px',
                  }}
                >
                  <img
                    src={BASE_URL + "/" + slide.image}
                    alt={`Slide ${index}`}
                    style={{
                      width: '100%',
                      height: '130px',
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          {/* Puntos de botón */}
          <div style={{ marginTop: '20px' }}>
            {promotionsData.promotions.slider_promo.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentSlide(index)}
                style={{
                  width: '12px',
                  height: '12px',
                  margin: '0 5px',
                  borderRadius: '6px',
                  backgroundColor: currentSlide === index ? '#602131' : '#C1A1A7',
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CarouselComponent;
