import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import clickVideo from "assets/images/clickVideo.svg";
import Rating from '@mui/material/Rating';
import { API_URL } from 'constants';

const EvaluacionDosComponent = ({tastingData}) => {
    const [selectedOptions, setSelectedOptions] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
  

    const [formData, setFormData] = useState({});

    const handleHover = (event) => {
        event.target.style.backgroundColor = '#602131'; // Cambia el color de fondo a rojo cuando hay hover
        event.target.style.color = '#ffffff'; // Cambia el color del texto a blanco cuando hay hover
    };

    const handleLeave = (event) => {
        event.target.style.backgroundColor = '#fff'; // Restaura el color de fondo original cuando el hover termina
        event.target.style.color = '#000'; // Restaura el color del texto original cuando el hover termina
    };

    const handleSelectChange = (event, preguntaId) => {
        const selectedAnswerId = event.target.value;
        setSelectedOptions(prevState => ({
            ...prevState,
            [preguntaId]: selectedAnswerId
        }));
    };

    const handleSaveTasting = () => {
        const authToken = localStorage.getItem('authToken');
        const wineId = localStorage.getItem('wine_id');
        const wineCodeFromCache = localStorage.getItem('codigo_wine');

        if (Object.entries(formData).length !== tastingData.tasting_questions4.length) {
            setSnackbarMessage("Por favor seleccione las respuestas para continuar");
            setSnackbarOpen(true);
        } else {

            fetch(`${API_URL}/save-tasting4`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(Object.entries(formData).map(([tasting_question_id, tasting_answer_id]) => ({
                    code: wineCodeFromCache,
                    wine_id: wineId,
                    tasting_question_id,
                    tasting_answer_id
                })))
            })
                .then(response => response.json())
                .then(data => {
                    setSnackbarMessage(data.message);
                    setSnackbarOpen(true);
                    if(data.reward_type === "video"){
                        window.location.href = '/insignia';
                    }else if(data.reward_type === "fisica"){
                        window.location.href = '/insigniaphy';   
                    }              
                })
                .catch(error => console.error('Error:', error));
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const styles = {
        container: {
            backgroundColor: '#ffff',
            color: '#602131',
            fontFamily: 'Arial, sans-serif',
            padding: '10px 20px',
            textAlign: 'left',
            margin: 0,
            boxSizing: 'border-box',
            position: 'relative'
            
        },
        image: {
            width: '100%',
            height: 'auto',
            marginBottom: '20px',
            display: 'block',
        },
        titleContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '0px',
            marginTop: '80px',
        },
        title: {
            fontFamily:'Athelas',
            fontWeight: 'bold',
            fontSize: '21px',
            marginBottom: '20px',
        },
        tutorialLink: {
            fontFamily:'Inter',
            fontWeight: 'bold',
            fontSize: '14px',
            color: '#602131',
            textDecoration: 'none',
            marginRight: '20px',
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: '20px',
            right: '20px',
        },
        clickVideo: {
            width: '40px',
            height: '40px',
            marginLeft: '5px',
        },
        pregunta: {
            fontFamily:'Athelas',
            fontWeight: 'bold',
            fontSize: '18px',
            marginBottom: '15px',
            marginTop: '15px',
        },
        selectContainer: {
            width: '100%',
            position: 'relative',
        },
        select: {
            width: '96%',
            padding: '10px',
            border: 'none',
            borderBottom: '1px solid #602131',
            backgroundColor: '#ffff',
            fontSize: '16px',
            color: '#602131',
            marginTop: '20px',
            marginBottom: '20px',
            outline: 'none',
        },
        btn: {
            backgroundColor: '#ffff',
            padding: '14px 30px',
            borderRadius: 50,
            border: '1px #602131 solid',
            color: '#602131',
            fontFamily:'Athelas',
            fontSize:'18px',
            fontWeight: 'bold',
            textAlign: 'center',
            cursor: 'pointer',
            display: 'block',
            width: '80%',
            margin: '0 auto',
            marginTop: '20px',
            marginBottom: '20px',
        },
        content: {
            marginBottom: '30px',
            fontFamily:'Avenir',
            fontSize: '14px',
        },
        rating: {
            color: '#602131',
        },

    };

    return (

        <div style={styles.container}>
            <a href="/video-general" style={styles.tutorialLink}>
                Ver tutorial de nuevo
                <img src={clickVideo} alt="Click Video" style={styles.clickVideo} />
            </a>
            <div style={styles.titleContainer}>
                <div style={styles.title}>Evaluación General</div>
            </div>
            <div style={styles.content}>
                {tastingData && <div>{tastingData.title}</div>}
            </div>
            {tastingData && tastingData.tasting_questions4.map((pregunta, index) => {
        
                    return(
                            <div key={index}>
                            <div style={styles.pregunta}>{pregunta.pregunta}</div>
                            <div style={styles.selectContainer}>
                            <Rating
                            name={"question"+pregunta.id}
                            style={styles.container}
                            size="large"
                            value={formData[index]}
                            //onChange={(event) => handleChange(event)}
                            onChange={(event, newValue) => {
                    
                            setFormData({
                                ...formData,
                                [pregunta.id]: newValue
                              })
                            }}
                            />
                            </div>  
                            </div>
                        );
                
            })}
            <button style={styles.btn} onMouseEnter={handleHover}
                    onMouseLeave={handleLeave}  onClick={handleSaveTasting}>Finalizar</button>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <MuiAlert elevation={1} variant="filled" onClose={handleSnackbarClose} severity="success">
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </div>

    );
};

export default EvaluacionDosComponent;
