import { useEffect, useState } from "react";
import logo from "assets/images/Logo-blanco.svg";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const styles = {
  page: {
    width: "100%",
    maxHeight: "100vh",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "flex",
  },

  img_wrapper_l: {
    height: "100%",
    width: "100%",
    position: "absolute",
    float: "left",
    marginTop: "0",
  },

  img_wrapper_r: {
    height: "100%",
    width: "100%",
    position: "absolute",
    float: "right",
    marginTop: "0",
  },

  img: {
    width: "100%",
    maxWidth: "900px",
    maxHeight: "500px",
    overflow: "hidden",
  },

  img2: {
    width: "20%",
    maxWidth: "900px",
    maxHeight: "500px",
    overflow: "hidden",
    marginLeft: "40.5%",
    marginTop: "4.7%",
  },
};

const ImageComponent = ({imageCover, messageEror}) => {

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  
    const handleLoad = () => {
      // Perform actions after the component has fully loaded
      console.log(messageEror);

      if(messageEror !== null)
      {

        console.log(messageEror);
        setSnackbarMessage(messageEror);
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setTimeout(() => {
          window.location.href="/perfil-code";
        }, 3000);
        
      }

    };
  
    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
    };

  return (
    <div style={styles.page}>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <img
          src={imageCover && imageCover}
          alt="Preguntas Header"
          style={styles.img}
          onLoad={handleLoad}
        />
      </div>
      <div style={styles.img_wrapper_r}>
        <a href="/">
          <img src={logo} alt="logo" style={styles.img2} />
        </a>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage} 
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ImageComponent;
