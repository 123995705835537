import React from "react";
import EvaluacionPrincipal from "components/App/EvaluacionGustativa";
import Footer from "components/App/Footer";
import WineEvaluationComponent from "components/App/WineEvaluationComponent";
import useTastingQuestions from "hooks/useTastingQuestions";

function LayoutTerm() {

  const [tastingData, imageCover, messageEror] = useTastingQuestions(
    "view-tastingquestions2",
    "paso_numero2",
    "error"
  );


  const styles = {
    container: {
      width: "100%", // Asegura que el contenedor ocupe todo el ancho disponible
      backgroundColor:'#FFFFFF'
    },
    image: {
      width: "100%",
      height: "auto",
      display: "block",
    },
  };

  return (
    <div style={styles.container}>
      <WineEvaluationComponent imageCover={imageCover} messageEror={messageEror} />
      <EvaluacionPrincipal tastingData={tastingData} />
      <Footer />
    </div>
  );
}

export default LayoutTerm;
