import React, { useState, useEffect } from 'react';
import SingleNavbar from 'components/App/SingleNavbar';
import MyData from 'components/App/DataProfile';
import Footer from 'components/App/Footer';
import BackButton from 'components/App/BackButton'

import headerBackGround from "assets/images/headerBackGround.png";
import headerBackGroundLarge from "assets/images/headerBackGroundLarge.png";
import NavbarWithStart from 'components/App/NavbarWithStart';
// Asegúrate de tener esta imagen

function LayoutMyData() {


  const styles = {
    body: {
      height: '100%',
      margin: 0,
      padding: 0,
      background: '#F7F3EF',/* Sets the background color of the app to a light beige */

    },
    container: {

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      margin: '65px',
      marginTop: '0',
      gap: '16px',
    },
    footer: {
      marginTop: '-30px',

    },
    BackButton: {
      marginTop: '30px',
      marginLeft: '30px',
      marginBottom: '-20px',

      alignItems: 'left',
    }

  };
  return (
    <div>
      <NavbarWithStart />
      <div style={styles.BackButton}>

        <BackButton />

      </div>
      <div style={styles.container}>

        <MyData />

      </div>

      <div style={styles.footer}>

        <Footer />

      </div>
    </div>
  );
}

export default LayoutMyData;
