import { Box, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const MenuNotLogged = () => {
  const buttonStyle = {
    color: "#FFFFFF",
    fontFamily: "Lora",
    fontStyle: "normal",
    fontSize: "18px",
    marginBottom: "30px",
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      sx={{ color: "#FFFFFF" }}
      padding={2}
    >
      <a href="/login" style={{ ...buttonStyle }}>
        Iniciar sesión
      </a>
      <a href="/registro" style={{ ...buttonStyle }}>
        Registro
      </a>
      <a href="/centro-ayuda" style={{ ...buttonStyle }}>
        Centro de ayuda
      </a>
    </Box>
  );
};

const MenuLogged = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/login");
  };
  const buttonStyle = {
    color: "#FFFFFF",
    fontFamily: "Lora",
    fontStyle: "normal",
    fontSize: "18px",
    marginBottom: "30px",
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"flex-start"}
      sx={{ color: "#FFFFFF" }}
    >
      <button
        style={{
          border: "none",
          background: "none",
          textDecoration: "none",
          padding: "0",
          ...buttonStyle,
        }}

        onClick={handleLogout}
      >
        Cerrar sesión
      </button>

      <a href="registro" style={{ ...buttonStyle }}>
        Registro
      </a>
      <a href="centro-ayuda" style={{ ...buttonStyle }}>
        Centro de ayuda
      </a>
      <a href="recompensas" style={{ ...buttonStyle }}>
        Recompensas
      </a>
      <a href="perfil-code" style={{ ...buttonStyle }}>
        Mi perfil
      </a>
      <a href="profile" style={{ ...buttonStyle }}>
        Mis Datos
      </a>
      <a href="mi-evaluacion" style={{ ...buttonStyle }}>
        Evaluaciones
      </a>
      <a href="datos-envio" style={{ ...buttonStyle }}>
        Datos de envío
      </a>
    </Box>
  );
};

const CustomMenu = ({ open, handleClose }) => {
  const isLoggedIn = !!localStorage.getItem("authToken"); // Determina si el usuario está logeado

  const styles = {
    modalBox: {
      maxWidth: "80%",
      backgroundColor: "#602131",
      padding: "10px",
      outline: "none",
      color: "#602131",
      textAlign: "center",
      fontFamily: "Athelas",
      position: "relative", // Agregado para el posicionamiento del botón de cierre
    },
    closeButton: {
      position: "absolute",
      top: "-5px",
      right: "15px",
      cursor: "pointer",
      color: "#000000",
      fontSize: "42px",
    },
    modalTitle: {
      top: "40px",

      color: "#602131",
      fontSize: "18px",
    },
    btn: {
      padding: "14px 30px",
      borderRadius: 50,
      border: "1px #602131 solid",
      color: "#602131",
      fontWeight: "bold",
      textAlign: "center",
      cursor: "pointer",
      display: "block",
      width: "80%",
      margin: "20px auto",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={styles.modalBox}>
        <Box
          marginBottom={3}
          width={"100%"}
          display={"flex"}
          justifyContent={"end"}
        >
          <IconButton
            size="large"
            aria-label="close"
            sx={{ cursor: "pointer", color: "white" }}
            onClick={handleClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Box>

        {isLoggedIn ? <MenuLogged /> : <MenuNotLogged />}

        <hr
          style={{
            backgroundColor: "#FFFFFF",
            border: "1px solid white",
            marginBottom: "50px",
          }}
        />
      </Box>
    </Modal>
  );
};

export default CustomMenu;
